@charset "UTF-8";

/* Functions */
/* Mixins */
/* Fonts */

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("//puraolea.com/cdn/fonts/montserrat/montserrat_n5.e72d00d31ae5399d260a80ec70322c62c1819762.woff2?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=0397ed0bf8d4cd7b11717ae6a975aed8e48ee6dc351f1c2bf72ef7de336d2033") format("woff2"),
       url("//puraolea.com/cdn/fonts/montserrat/montserrat_n5.18a018b6c83e89030c5d70a6d4c02c969f228500.woff?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=a612007228551853d751a392f6cc40512284457ba74f04ffe3e26dc3faf72e0e") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("//puraolea.com/cdn/fonts/montserrat/montserrat_n7.c496e9cf2031deec4c4bca338faa81971c8631d4.woff2?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=ea0447ce12409a3ea443442686558193b1ee90a04198638745abba3c0145e9c9") format("woff2"),
       url("//puraolea.com/cdn/fonts/montserrat/montserrat_n7.78b0223375c94b39ce1af7e09a0225f2bb3d05f7.woff?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=8f491fe9889f02cc1271eafd2b0ea90aca8af95af1a214ba0889fae2a54c5f6b") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("//puraolea.com/cdn/fonts/montserrat/montserrat_n5.e72d00d31ae5399d260a80ec70322c62c1819762.woff2?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=0397ed0bf8d4cd7b11717ae6a975aed8e48ee6dc351f1c2bf72ef7de336d2033") format("woff2"),
       url("//puraolea.com/cdn/fonts/montserrat/montserrat_n5.18a018b6c83e89030c5d70a6d4c02c969f228500.woff?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=a612007228551853d751a392f6cc40512284457ba74f04ffe3e26dc3faf72e0e") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("//puraolea.com/cdn/fonts/montserrat/montserrat_n7.c496e9cf2031deec4c4bca338faa81971c8631d4.woff2?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=ea0447ce12409a3ea443442686558193b1ee90a04198638745abba3c0145e9c9") format("woff2"),
       url("//puraolea.com/cdn/fonts/montserrat/montserrat_n7.78b0223375c94b39ce1af7e09a0225f2bb3d05f7.woff?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=8f491fe9889f02cc1271eafd2b0ea90aca8af95af1a214ba0889fae2a54c5f6b") format("woff");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("//puraolea.com/cdn/fonts/work_sans/worksans_n4.29e3afeb38a0ba35e784cf169a40e8beaf814daa.woff2?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=4065a73a7f6314ee611e6a7d376ca9eaf32e18254c312aa3570d0e6510b84f88") format("woff2"),
       url("//puraolea.com/cdn/fonts/work_sans/worksans_n4.e7c533c4afbed28070f6ac45dbcfe6f37840c0a8.woff?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=a9a136284345c8bb63d8b0c52c16d217ed7ec54eacf12055c7708753499486cf") format("woff");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("//puraolea.com/cdn/fonts/work_sans/worksans_n7.35eac55373d3da50c529c81066eb2f2f0fbedb82.woff2?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=db0028796186ee9f7753c2759fa1959a5a6db241e0ba35baaf1a4c48df4a492a") format("woff2"),
       url("//puraolea.com/cdn/fonts/work_sans/worksans_n7.1b010d40a44f517d5363112c4aff386332758bc9.woff?h1=cHVyYW9sZWEuY29t&h2=cHVyYS1vbGVhLW9saXZlLW9pbHMtMi5hY2NvdW50Lm15c2hvcGlmeS5jb20&hmac=66397023e0ed3b1a3f6725ff092c3e52898a834b5ad86dc429dd9f99138eb4bf") format("woff");
}



/* Reset */
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background: #fff;
  color: #000;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html, body {
  font-size: 100%;
}

img {
  border: 0;
  display: block;
  -ms-interpolation-mode: bicubic;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

button, input, textarea, select {
  border-radius: 0;
  font-family: inherit;
  font-size: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.no-outlines button, .no-outlines input, .no-outlines textarea, .no-outlines select {
  outline: none;
}

a {
  color: black;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.no-outlines a {
  outline: none;
}

.no-outlines details summary {
  outline: none;
}
details summary::-webkit-details-marker {
  display: none;
}

@font-face {
  font-family: 'FontColors';
  src: url('//puraolea.com/cdn/shop/t/9/assets/colors.eot?v=5070710449693833891637797296');
  src: url('//puraolea.com/cdn/shop/t/9/assets/colors.eot?%23iefix&v=5070710449693833891637797296') format('embedded-opentype'), url('//puraolea.com/cdn/shop/t/9/assets/colors.woff?v=107005334659925142071637797298') format('woff'), url('//puraolea.com/cdn/shop/t/9/assets/colors.ttf?v=182018338699013960361637797297') format('truetype'), url('//puraolea.com/cdn/shop/t/9/assets/colors.svg?v=58875128585777966651637797295') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.fc {
  display: inline-block;
  font: normal normal normal 14px/1 FontColors;
  font-size: inherit;
  text-rendering: auto;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fc-hamburguer:before {
  content: "1";
}

.fc-cart:before {
  content: "3";
}

.fc-search:before {
  content: "2";
}

.fc-comment:before {
  content: "c";
}

.fc-tag:before {
  content: "b";
}

.fc-pencil:before {
  content: "e";
}

.fc-trash:before {
  content: "d";
}

.fc-close:before {
  content: "x";
}

.fc-facebook:before {
  content: "f";
}

.fc-pinterest:before {
  content: "p";
}

.fc-google:before {
  content: "g";
}

.fc-linkedin:before {
  content: "l";
}

.fc-instagram {
  transform: scale(0.75);
  position: relative;
  top: -2px;
}

.fc-instagram:before {
  content: "E";
}

.fc-twitter:before {
  content: "w";
}

.fc-vimeo:before {
  content: "v";
}

.fc-tumblr:before {
  content: "t";
}

.fc-flickr:before {
  content: "k";
}

.fc-yelp:before {
  content: "o";
}

.fc-snapchat:before {
  content: "j";
}

.fc-youtube:before {
  content: "h";
}

.fc-rss:before {
  content: "a";
}

.fc-bullet:before {
  content: "A";
}

.fc-bullet-active:before {
  content: "B";
}

.fc-user:before {
  content: "m";
}

.fc-icon-eco:before {
  content: "n";
}

.fc-icon-international:before {
  content: "q";
}

.fc-icon-local:before {
  content: "r";
}

.fc-icon-recycled:before {
  content: "s";
}

.fc-icon-returns:before {
  content: "u";
}

.fc-icon-sale:before {
  content: "z";
}

.fc-icon-security:before {
  content: "C";
}

.fc-icon-shipping:before {
  content: "D";
}

.fc-twitch {
  transform: scale(0.7);
}

.fc-twitch:before {
  content: "i";
}

.fc-reddit {
  transform: scale(0.9);
  position: relative;
  top: -3px;
}

.fc-reddit:before {
  content: "H";
}

.fc-github {
  transform: scale(0.7);
}

.fc-github:before {
  content: "I";
}

/* UI */
html {
  background: #ffffff;
}

body {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

h2.shopify-section-title {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 29px;
  color: #2a2d32;
  color: #a3be55;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  margin: 0 0 15px;
}
h2.shopify-section-title.divider {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 20px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  padding: 0 6px 0 8px;
  text-decoration: none;
}
h2.shopify-section-title.rss {
  border-bottom: 0;
  font-size: 20px;
  margin-left: 3px;
}

.section-header a {
  text-decoration: none;
}

.pagination {
  clear: both;
  width: 100%;
  padding: 30px 0 10px;
}
.pagination .page, .pagination .next, .pagination .prev {
  margin: 0 4px;
  padding: 0 1px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #2a2d32;
}
.pagination .page a, .pagination .next a, .pagination .prev a {
  color: #2a2d32;
  text-decoration: none;
}
.pagination .page a h2, .pagination .next a h2, .pagination .prev a h2 {
  font-size: inherit;
}
.pagination .page a:hover, .pagination .next a:hover, .pagination .prev a:hover {
  border-bottom: 2px solid #a3be55;
}
.pagination .page.current, .pagination .next.current, .pagination .prev.current {
  border-bottom: 2px solid #a3be55;
}
.pagination h2 {
  display: inline;
  text-decoration: underline;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #2a2d32;
}
.pagination h2.divider {
  text-decoration: none;
  padding: 0 6px 0 8px;
}

h3.empty {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  margin: 30px 10px;
}

hr {
  display: block;
  max-width: 70px;
  height: 3px;
  background: #2a2d32;
  border: 0;
  margin: 0 0 10px;
  text-align: left;
}
hr.short {
  max-width: 20px;
}

p {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  line-height: 21px;
  color: #2a2d32;
}
p b, p strong {
  font-weight: 700;
}
p a {
  color: #2a2d32;
  text-decoration: none;
}
p.big, p.expanded {
  font-size: 14px;
  line-height: 24px;
}
p.small {
  font-size: 12px;
}

span.highlight {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  font-weight: 700;
  font-size: inherit;
  line-height: inherit;
  background: #ffffff;
  padding: 1px 4px;
}

.boxed {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  border: 2px solid;
  padding: 4px 10px 3px;
}

.icon {
  display: inline;
}

.btn {
  text-decoration: none;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  font-weight: ;
  display: inline-block;
  text-align: center;
  padding: 10px;
  background: transparent;
  border: 0;
  transition: background 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
  transition: color 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
  transition: border-color 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.btn:before, .btn:after {
  transition: background 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
  transition: color 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
  transition: border-color 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.btn.border {
  border: 3px solid;
}
.btn.border:hover {
  color: #595f69;
  border-color: #595f69;
}
.btn.border:hover:before, .btn.border:hover:after {
  border-color: #595f69;
}
.btn.border.small {
  font-size: 16px;
  padding: 5px;
  border-width: 2px;
}
.btn.solid {
  background: #2a2d32;
  border-color: #2a2d32;
  color: #ffffff;
}
.btn.solid:hover {
  background: #363940;
}
.btn.solid.small {
  font-size: 16px;
  padding: 8px;
}
.btn.accent {
  background: #a3be55;
  border-color: #a3be55;
  color: #ffffff;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.btn.accent:hover {
  background: #98b445;
}
.btn.accent.adding {
  opacity: 0.25;
  cursor: default;
}
.btn.accent.added {
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 1s;
  opacity: 1;
}
.btn.accent.small {
  font-size: 16px;
  padding: 8px;
}
.btn.text {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  padding: 7px 10px;
}
.btn.disabled {
  cursor: default;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.btn.disabled:hover {
  color: inherit;
  border-color: inherit;
  background-color: inherit;
}
.btn.full {
  width: 100%;
}
.btn.rounded {
  border-radius: 3px;
}
.btn.faded {
  opacity: 0.8;
}

.disclosure {
  position: relative;
}
.disclosure .disclosure__toggle {
  border: 1px solid #333;
  border-radius: 3px;
  background: white;
  cursor: pointer;
  padding: 8px 15px 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
  color: #333;
  line-height: 1.2;
  text-indent: 0.01px;
  white-space: nowrap;
}
.disclosure .disclosure__toggle .shopify-select-icon {
  cursor: pointer;
  display: inline-block;
  fill: #798c9c;
  pointer-events: none;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  vertical-align: middle;
}
.disclosure .disclosure__list {
  display: none;
  background-color: #ffffff;
  position: absolute;
  z-index: 200;
  bottom: 115%;
  overflow-y: auto;
  border: 1px solid #2a2d32;
  padding: 11px 0px;
  border-radius: 2px;
}
.disclosure .disclosure__list.disclosure-list--visible {
  display: block;
}
.disclosure .disclosure__list li {
  list-style: none;
  white-space: nowrap;
  text-align: left;
  padding: 5px 15px 4px;
}
.disclosure .disclosure__list li a {
  color: #2a2d32;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
.disclosure .disclosure__list li a:hover {
  color: #2a2d32;
  border-color: #2a2d32;
}
.disclosure .disclosure__list li.disclosure-list__item--current a {
  border-color: #2a2d32;
}

.shape-wrapper, .circle-wrapper {
  display: inline-block;
}

.shape {
  opacity: 0;
}
.no-js .shape {
  opacity: 1;
}
.shape.shaped {
  opacity: 1;
}
.shape.shaped.square.solid {
  padding: 10px;
  background: #2a2d32;
  border-color: #2a2d32;
  color: #ffffff;
}
.shape.shaped.circle {
  display: table;
  table-layout: fixed;
}
.shape.shaped.circle span {
  display: table-cell;
  vertical-align: middle;
}
.shape.shaped.hexagon {
  position: relative;
  text-align: center;
  display: table;
  text-decoration: none;
  border-collapse: collapse;
  padding: 0;
}
.shape.shaped.hexagon:before, .shape.shaped.hexagon:after {
  content: "";
  position: absolute;
  z-index: 1;
  background-color: inherit;
}
.shape.shaped.hexagon span {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 2;
}
.shape.shaped.hexagon.border {
  border-top: 0;
  border-bottom: 0;
  border-width: 3px;
}
.shape.shaped.hexagon.solid {
  border: 0;
}
.shape.shaped.hexagon.solid:before, .shape.shaped.hexagon.solid:after {
  border-color: transparent;
}
.shape.shaped.hexagon.accent {
  border: 0;
}
.shape.shaped.hexagon.accent:before, .shape.shaped.hexagon.accent:after {
  border-color: transparent;
}

button.shape.shaped.circle span, button.shape.shaped.hexagon span {
  display: block;
}

a, button {
  color: #2a2d32;
  background: transparent;
  border: 0;
}
a.underline, button.underline {
  text-decoration: none;
  border-bottom: 1px solid;
  cursor: pointer;
}
a.link, button.link {
  text-decoration: none;
}
a.link:hover, button.link:hover {
  border-bottom: 1px solid;
}

form input, form textarea, form select, form label, form .shopify-select {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  text-transform: none;
}
form input.long, form textarea.long, form select.long, form label.long, form .shopify-select.long {
  width: 100%;
  max-width: 400px;
}
form input.full, form textarea.full, form select.full, form label.full, form .shopify-select.full {
  width: 100%;
}
form input.error, form textarea.error, form select.error, form label.error, form .shopify-select.error {
  background: #d5e1b1;
}
form input[type=number]::-webkit-inner-spin-button,
form input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
form input, form textarea, form select {
  background: #ffffff;
}
form input.text, form textarea.text {
  border: 0;
  padding: 8px 10px 7px;
}
form textarea {
  font-size: 16px;
  line-height: 22px;
  min-height: 200px;
}
form input[type=search] {
  -webkit-appearance: none;
}
form input[type=submit] {
  -webkit-appearance: none;
}
form h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 22px;
  color: #2a2d32;
  font-weight: 700;
}
form h3 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
}
form .field-error {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  text-transform: initial;
  margin: 10px 0 15px;
}

ul.inline {
  list-style-type: none;
}
ul.inline li {
  display: inline;
}

.hide, .visually-hidden {
  display: none;
}

.js-focus-hidden:focus {
  outline: none;
}

.skip-link {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  left: 0;
  top: 0;
}
.skip-link:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  padding: 15px;
  opacity: 1;
  z-index: 10000;
  transition: none;
  background: white;
  color: black;
}

.rte {
  word-wrap: break-word;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  line-height: 28px;
  color: #2a2d32;
}
.rte > * {
  line-height: normal;
}
.rte h1, .rte h2, .rte h3, .rte h4, .rte h5, .rte h6 {
  margin: 20px 0 10px;
  text-decoration: none;
}
.rte h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 40px;
  color: #2a2d32;
  font-weight: 700;
  text-transform: none;
}
.rte h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 32px;
  color: #2a2d32;
  font-weight: 700;
  text-transform: none;
}
.rte h3 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 28px;
  color: #2a2d32;
  font-weight: 700;
  text-transform: none;
}
.rte h4 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 24px;
  color: #2a2d32;
  font-weight: 700;
  text-transform: none;
}
.rte h5 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 20px;
  color: #2a2d32;
  font-weight: 700;
  text-transform: none;
}
.rte h6 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  font-weight: 700;
  text-transform: none;
}
.rte p, .rte li, .rte td {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  line-height: 28px;
  color: #2a2d32;
}
.rte p {
  margin: 0 0 20px;
}
.rte p.category {
  color: #a3be55;
  margin-bottom: 0px;
}
.rte p:last-child {
  margin: 0;
}
.rte a:not(.btn) {
  color: #a3be55;
  text-decoration: none;
}
.rte a:not(.btn):hover {
  border-bottom: 1px solid;
}
.rte blockquote {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 28px;
  line-height: 38px;
  color: #2a2d32;
  text-transform: none;
  margin: 40px 80px;
}
.rte ul, .rte ol {
  margin: 0 0 20px 30px;
}
.rte ul li, .rte ol li {
  margin: 0 0 10px;
}
.rte img, .rte iframe {
  max-width: 100%;
}
.rte table {
  margin: 0 0 5px;
  border-collapse: collapse;
  max-width: 100%;
}
.rte table td {
  padding: 10px;
  border: 1px solid #2a2d32;
  text-align: left;
}
.rte table thead td {
  font-weight: bold;
}
.rte b, .rte strong {
  font-weight: 700;
}
.rte.center {
  text-align: center;
  margin: 0 auto;
}
.rte.center ul, .rte.center ol {
  margin-left: 0;
}
.rte.center ul li, .rte.center ol li {
  list-style: none;
}

.shopify-select {
  border: 1px solid #d3dbe2;
  border-radius: 3px;
  box-sizing: border-box;
  position: relative;
  background: #ffffff;
  overflow: hidden;
  vertical-align: bottom;
  max-width: 100%;
}
.shopify-select select {
  color: #333;
  font-size: 16px;
  padding: 7px 10px;
  padding-right: 32px;
  border: 0;
  width: 100%;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.shopify-select .shopify-select-icon {
  cursor: pointer;
  display: block;
  fill: #798c9c;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -6px;
  pointer-events: none;
  width: 12px;
  height: 12px;
  vertical-align: middle;
}
.shopify-select.short {
  max-width: 100px;
}
.shopify-select.auto {
  display: inline-block;
}

.placeholder-svg {
  fill: rgba(42, 45, 50, 0.25);
  width: 100%;
  height: 100%;
}
.placeholder-svg.with-white-background {
  border: 1px solid rgba(42, 45, 50, 0.25);
  background: white;
}
.placeholder-svg.with-border {
  border: 1px solid rgba(42, 45, 50, 0.25);
}

@media (max-width: 620px) {
  .section {
    margin: 0 0 15px;
  }

  h3.empty {
    margin: 15px;
  }

  .rte blockquote {
    margin-left: 0;
    margin-right: 0;
    font-size: 22px;
    line-height: 32px;
  }
  .rte.center blockquote {
    padding: 0 15px;
  }
  .rte.center blockquote:before {
    content: normal;
    margin-right: 0;
  }

  .disclosure .disclosure__list li {
    padding: 10px 15px;
  }
}
/* Layout */
.no-js:not(html) {
  display: none;
}
.no-js .no-js:not(html) {
  display: block;
}

.no-js .js {
  display: none;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  max-width: 2000px;
  margin: 0 auto;
}

body.browsing .header, body.browsing .main, body.browsing .side-nav .inner {
  transform: translateX(400px);
}
body.shopping .header, body.shopping .main, body.shopping .side-cart {
  transform: translateX(-400px);
}
body.shopping .side-cart {
  visibility: visible;
}
body.picking .header, body.picking .main, body.picking .side-pick {
  transform: translateX(-400px);
}
body.picking .side-pick {
  visibility: visible;
}
body.searching .search-overlay {
  top: 0 !important;
  visibility: visible;
}
body.searching .search-overlay .search-bar-wrapper {
  opacity: 1;
}
body.browsing, body.shopping, body.searching, body.picking {
  overflow: hidden;
  height: 100%;
}

.header, .main, .side-nav .inner {
  transition: transform 0.45s cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}

.side-cart, .side-pick {
  transition: transform 0.45s cubic-bezier(0.46, 0.01, 0.32, 1) 0s, visibility 0.45s cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}

body {
  display: flex;
  flex-direction: column;
}
body .main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
body .main .layout {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
body .main .layout .shopify-section.section-page,
body .main .layout .shopify-section.section-search,
body .main .layout .shopify-section.section-password,
body .main .layout .shopify-section.section-404,
body .main .layout .shopify-section.section-cart,
body .main .layout .shopify-challenge__container,
body .main .layout .shopify-policy__container,
body .main .layout .shopify-email-marketing-confirmation__container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
body .main .layout .shopify-section.section-page .content,
body .main .layout .shopify-section.section-search .content,
body .main .layout .shopify-section.section-password .content,
body .main .layout .shopify-section.section-404 .content,
body .main .layout .shopify-section.section-cart .content,
body .main .layout .shopify-challenge__container .content,
body .main .layout .shopify-policy__container .content,
body .main .layout .shopify-email-marketing-confirmation__container .content {
  flex: 1 0 auto;
}

.main {
  width: 100%;
  padding: 0 0 0;
}
.main .layout .shopify-section .content {
  padding: 30px;
  margin: 30px 30px 0;
}
.main .layout .shopify-section .content.no-padding {
  padding: 0;
}
.main .layout .shopify-section .content.show-background {
  background: #ffffff;
}
.main .layout .shopify-section .content.expanded {
  margin-left: 0;
  margin-right: 0;
}
.main .layout .shopify-section .content.collapsed {
  margin-top: 0;
}
.main .layout .shopify-section .content.standalone {
  margin-top: 30px;
}
.main .layout .shopify-section .content.standalone.expanded {
  margin-top: 0;
  margin-bottom: 0;
}
.main .layout .shopify-section .content .inner {
  margin: 0 auto;
}
.main .layout .shopify-section .content .inner > a {
  text-decoration: none;
}
.main .layout .shopify-section .content .inner.with-layout-options {
  max-width: 500px;
}
.main .layout .shopify-section .content .inner.with-layout-options.left {
  margin: 0;
  text-align: left;
}
.main .layout .shopify-section .content .inner.with-layout-options.right {
  margin-left: auto;
  text-align: right;
}
.main .layout .shopify-section .content .inner.with-layout-options.centered {
  margin: 0 auto;
}
.main .layout .shopify-section .content .inner.with-layout-options.expanded {
  max-width: none;
}
.main .layout .shopify-section .content .inner.extended {
  padding: 30px 0;
}
.main .layout .shopify-section .content .inner.extended h1 {
  margin-top: 0;
}

@media (min-width: 620px) {
  .hide-on-desktop {
    display: none !important;
  }
}

@media (max-width: 620px) {
  .hide-on-mobile {
    display: none !important;
  }
}

.no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 2px dashed #a3be55;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  line-height: 30px;
  padding: 30px;
}
.no-content a {
  color: #a3be55;
  border-bottom: 2px solid;
}

.boxes {
  padding: 30px 0 60px;
}
.show-background .boxes {
  background: #ffffff;
}
.boxes .box {
  width: 100%;
  padding: 0 0 30px;
}
.boxes .box:after {
  display: table;
  content: '';
  clear: both;
}
.boxes .box > .left {
  float: left;
  width: 25%;
  height: 100%;
  text-align: right;
  padding: 60px 0 0;
}
.boxes .box > .left h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 40px;
  line-height: 56px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  text-decoration: none;
  display: inline;
  position: relative;
  top: -15px;
  right: -50px;
}
.boxes .box > .right {
  margin-left: 25%;
  padding: 60px 60px 60px 110px;
  height: 100%;
}
.boxes .box > .right > h3, .boxes .box > .right h3.accent {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  margin: 0 0 25px;
}
.boxes .box > .right > form h3 {
  margin: 0 0 10px;
}
.boxes .box > .right > .row h3 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  margin: 0 0 10px;
}
.boxes .box > .right .back {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  color: #2a2d32;
}
.boxes .cart_box{
  width: 100%;
  padding: 0 0 30px;
}
.show-background .boxes {
  background: #ffffff;
}

.boxes .cart_box > .right {
  margin-left: 25%;
  padding: 60px 60px 60px 110px;
  height: 100%;
  background: #f2f2f2;
}
.boxes .cart_box > .right > h3, .boxes .box > .right h3.accent {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  margin: 0 0 25px;
}
.boxes .cart_box > .right > form h3 {
  margin: 0 0 10px;
}
.boxes .cart_box > .right > .row h3 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  margin: 0 0 10px;
}
.boxes .cart_box > .right .back {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  color: #2a2d32;
}

.row {
  margin: 0 0 10px;
}
.row.expanded {
  margin-bottom: 20px;
}
.row.expanded-top {
  margin-top: 20px;
}
.row.center {
  text-align: center;
}
.row.right {
  text-align: right;
}
.row:last-child {
  margin-bottom: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flex-row:last-child {
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .flex-row {
    flex-direction: column;
  }
}

.share {
  margin: 35px 0 0;
  min-height: 56px;
}
.share a.icon {
  font-size: 28px;
  text-decoration: none;
  border-radius: 50%;
  background: red;
  text-align: center;
  width: 36px;
  height: 36px;
  margin: 0 5px 0 0;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-family: monospace;
  padding-top: 7px;
}
.share a.icon.facebook {
  background-color: #3b5998;
}
.share a.icon.twitter {
  background-color: #4099FF;
}
.share a.icon.pinterest {
  background-color: #C92228;
}

body.template-customers-login .footer,
body.template-customers-account .footer,
body.template-customers-addresses .footer,
body.template-customers-register .footer,
body.template-404 .footer,
body.template-cart .footer,
body.template-gift_card .footer {
  display: none;
}

@media (max-width: 960px) {
  .main .layout .shopify-section .content {
    padding: 20px;
    margin: 20px 20px 0;
  }

  .boxes {
    padding: 30px 0;
  }
  .boxes .box > .left h2 {
    font-size: 30px;
    line-height: 40px;
    right: -25px;
  }
  .boxes .box > .right {
    padding: 60px;
  }
}
@media (max-width: 620px) {
  .main .layout .shopify-section .content {
    padding: 15px;
    margin: 15px 15px 0;
  }
  .main .layout .shopify-section .content:not(.show-background).expanded {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .main .layout .shopify-section.section-featured-image .content:not(.show-background).expanded, .main .layout .shopify-section.section-featured-video .content:not(.show-background).expanded, .main .layout .shopify-section.section-featured-products .content:not(.show-background).expanded {
    padding-top: 0;
    padding-bottom: 0;
  }

  .boxes {
    padding: 0;
  }
  .boxes .box {
    padding: 0;
  }
  .boxes .box > .left {
    padding: 30px 0;
    float: none;
    width: 100%;
    text-align: left;
  }
  .boxes .box > .left h2 {
    font-size: 30px;
    line-height: 40px;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
  }
  .boxes .box > .left h2 br {
    display: inline-block;
    margin-right: 8px;
    content: ' ';
  }
  .boxes .box > .right {
    margin: 0;
    padding: 30px 15px;
  }
  .boxes .box > .right:after {
    display: table;
    content: '';
    clear: both;
  }
  .boxes .box > .right > h3 {
    margin: 0 0 20px;
  }
  .boxes .box > .right .actions, .boxes .box > .right .back {
    text-align: right;
    clear: both;
  }
}
/* Filters  */
.sorting-and-filtering .label {
  margin-right: 10px;
  display: inline-block;
}
.sorting-and-filtering form input[type=checkbox] {
  filter: grayscale(1);
  margin-right: 5px;
  position: relative;
  top: 1px;
}
.sorting-and-filtering form label, .sorting-and-filtering form select {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  cursor: pointer;
}
.sorting-and-filtering form input:disabled + label {
  color:  #595f69;
  cursor: default;
}
.sorting-and-filtering form .price-range {
  display: flex;
  margin: 0 0 7px;
}
.sorting-and-filtering form .price-range .price:first-child {
  margin-right: 20px;
}
.sorting-and-filtering form .price-range .price input {
  padding: 8px 10px 7px;
  border: 1px solid #d3dbe2;
}
.sorting-and-filtering form.desktop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.sorting-and-filtering menu-drawer details summary {
  position: relative;
  cursor: pointer;
  text-align: center;
  list-style: none;
}
.sorting-and-filtering menu-drawer details summary span.count {
  margin-left: 5px;
}
.sorting-and-filtering menu-drawer details form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.sorting-and-filtering menu-drawer details form .inner {
  width: calc(100% - 50px);
  margin-left: auto;
  height: 100%;
  overflow-y: auto;
  pointer-events: all;
  background: #ffffff;
}
.sorting-and-filtering menu-drawer details form .inner .header {
  display: flex;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 2;
  border-bottom: 1px solid #2a2d32;
  text-align: center;
  flex-direction: column;
  padding: 15px;
}
.sorting-and-filtering menu-drawer details form .inner .header h2.mobile-facets__heading {
  margin: 0;
}
.sorting-and-filtering menu-drawer details form .inner .menu {
  position: relative;
  z-index: 1;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details {
  border-bottom: 1px solid #595f69;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details.last {
  border-bottom: 0;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details .summary {
  padding: 15px;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details .summary > div {
  display: flex;
  align-items: baseline;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details .summary > div .arrow {
  margin-left: auto;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details .summary > div .arrow svg {
  height: 10px;
  fill: #595f69;
  transform: rotate(0deg);
}
.sorting-and-filtering menu-drawer details form .inner .menu .details .summary > div .select {
  margin-left: auto;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details .submenu {
  padding: 0 15px 5px;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details .submenu ul {
  list-style: none;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details .submenu ul li {
  margin: 0 0 10px;
  display: flex;
  align-items: baseline;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details .submenu ul li label {
  width: 100%;
}
.sorting-and-filtering menu-drawer details form .inner .menu .details[open] .summary > div .arrow svg {
  transform: rotate(-180deg);
}
.sorting-and-filtering menu-drawer details form .inner .price-range {
  margin-top: 10px;
}
.sorting-and-filtering menu-drawer details form .inner .price-range .price input {
  width: 90px;
}
.sorting-and-filtering menu-drawer details form .inner .footer {
  padding: 15px;
  border-top: 1px solid #2a2d32;
  display: flex;
  position: sticky;
  bottom: 0;
  background: #ffffff;
  margin-top: 0;
  align-items: stretch;
  align-content: stretch;
}
.sorting-and-filtering menu-drawer details form .inner .footer a, .sorting-and-filtering menu-drawer details form .inner .footer button {
  display: block;
}
.sorting-and-filtering menu-drawer > details > summary .btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 10px;
}
.sorting-and-filtering menu-drawer > details > summary .btn svg {
  stroke: #2a2d32;
  margin-right: 10px;
  height: 50px;
}
.sorting-and-filtering menu-drawer > details > summary .close {
  display: none;
}
.sorting-and-filtering menu-drawer > details > summary + * {
  z-index: 100;
}
.sorting-and-filtering menu-drawer > details[open] > summary .close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 101;
}
.sorting-and-filtering .filters > details > summary::before, .sorting-and-filtering menu-drawer > details > summary::before {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  cursor: default;
  content: ' ';
  background: 0 0;
}
.sorting-and-filtering .filters > details[open] > summary::before, .sorting-and-filtering menu-drawer > details[open] > summary::before {
  height: 100vh;
  display: block;
}
.sorting-and-filtering .filtering .filters {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.sorting-and-filtering .filtering .filter-group {
  margin-right: 10px;
}
.sorting-and-filtering .filtering .filter-group .filter-group-summary {
  position: relative;
  cursor: pointer;
  list-style: none;
  border: 1px solid #d3dbe2;
  border-radius: 3px;
  background: #ffffff;
  overflow: hidden;
  vertical-align: bottom;
  max-width: 100%;
  margin-bottom: 10px;
}
.sorting-and-filtering .filtering .filter-group .filter-group-summary > div {
  display: inline-block;
  color: #333;
  font-size: 16px;
  padding: 7px 10px;
  padding-right: 32px;
}
.sorting-and-filtering .filtering .filter-group .filter-group-summary > div span.count {
  margin-left: 5px;
}
.sorting-and-filtering .filtering .filter-group .filter-group-summary .shopify-select-icon {
  cursor: pointer;
  display: block;
  fill: #798c9c;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -6px;
  pointer-events: none;
  width: 12px;
  height: 12px;
  vertical-align: middle;
}
.sorting-and-filtering .filtering .filter-group .filter-group-display {
  border: 1px solid #d3dbe2;
  border-radius: 3px;
  background: #ffffff;
  z-index: 100;
  position: absolute;
}
.sorting-and-filtering .filtering .filter-group .filter-group-display .filter-group-header,
.sorting-and-filtering .filtering .filter-group .filter-group-display .filter-group-content {
  padding: 7px 10px;
}
.sorting-and-filtering .filtering .filter-group .filter-group-display .filter-group-header {
  border-bottom: 1px solid #d3dbe2;
}
.sorting-and-filtering .filtering .filter-group .filter-group-display .filter-group-header a.reset {
  margin-left: 50px;
}
.sorting-and-filtering .filtering .filter-group .filter-group-display .filter-group-content ul {
  list-style: none;
  margin: 0 0 7px;
}
.sorting-and-filtering .filtering .filter-group .filter-group-display .filter-group-content ul li {
  margin: 0 0 5px;
  padding-right: 5px;
}
.sorting-and-filtering .filtering .filter-group .filter-group-display .filter-group-content ul li:hover label {
  text-decoration: underline;
}
.sorting-and-filtering .filtering .filter-group .filter-group-display .filter-group-content ul li:hover input:disabled + label {
  text-decoration: none;
}
.sorting-and-filtering .sorting {
  flex-shrink: 0;
}
.sorting-and-filtering .sorting .sort {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.sorting-and-filtering .sorting .sort button {
  margin-left: 10px;
}
.sorting-and-filtering .sorting .select {
  margin-bottom: 10px;
}
.sorting-and-filtering .active-facets {
  display: flex;
  flex-wrap: wrap;
}
.sorting-and-filtering .active-facets a.btn, .sorting-and-filtering .active-facets button {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.sorting-and-filtering .active-facets a.btn i.fc, .sorting-and-filtering .active-facets button i.fc {
  margin-left: 10px;
}
.sorting-and-filtering .active-facets a.btn:only-child {
  display: none;
}
.no-js .sorting-and-filtering ul.categories {
  display: none;
}
.sorting-and-filtering ul.categories li {
  display: inline-block;
}
.sorting-and-filtering ul.categories li a {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
  color: #2a2d32;
  text-decoration: none;
  margin: 10px 20px 0 0;
  display: none;
}
.sorting-and-filtering ul.categories li a span {
  border-bottom: 2px solid transparent;
}
.sorting-and-filtering ul.categories li a sup {
  font-size: 10px;
  margin: 0 0 0 4px;
  opacity: 0;
}
.sorting-and-filtering ul.categories li a:hover span, .sorting-and-filtering ul.categories li a.active span {
  border-bottom-color: #a3be55;
}
.sorting-and-filtering ul.categories li a.loaded {
  display: inline-block;
}
.sorting-and-filtering ul.categories li a.loaded sup {
  opacity: 1;
}
.sorting-and-filtering ul.categories li.all a {
  display: inline-block;
}

@media (max-width: 620px) {
  .sorting-and-filtering form.desktop {
    flex-direction: column;
  }
  .sorting-and-filtering form.desktop .filtering {
    width: 100%;
    order: 2;
  }
  .sorting-and-filtering form.desktop .filtering ul.categories {
    overflow: scroll;
    display: block;
    white-space: nowrap;
    padding: 0 0 10px;
  }
  .sorting-and-filtering form.desktop .filtering ul.categories li {
    display: inline-block;
  }
  .sorting-and-filtering form.desktop .filtering ul.categories li a {
    margin: 0 5px 0 0;
  }
  .sorting-and-filtering form.desktop .sorting {
    width: 100%;
  }
  .sorting-and-filtering form.desktop .sorting .sort:not(.with-label) .select {
    width: 100%;
    margin-bottom: 20px;
  }
}
/* Header */
.header {
  position: relative;
  width: 100%;
}
.header:after {
  display: table;
  content: '';
  clear: both;
}
.header .relative {
  position: relative;
}
.header .bar {
  padding: 17px 30px;
  position: relative;
  z-index: 100;
}
.header .bar.fixed {
  display: none;
  transition: background-color 350ms cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
.header .bar .center {
  position: relative;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
  line-height: 0;
  transition: top 350ms cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
.header .bar .center a.logo {
  text-decoration: none;
  display: inline-block;
  max-width: 70%;
}
.header .bar .left, .header .bar .right {
  height: 26px;
  z-index: 10;
}
.header .bar .left {
  position: absolute;
  left: 19px;
  transition: left 350ms cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
.header .bar .left a {
  text-decoration: none;
  line-height: 0;
}
.header .bar .left a i {
  color: #2a2d32;
  font-size: 30px;
  vertical-align: middle;
}
.header .bar .left a span {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  margin-left: 4px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.header .bar .right {
  position: absolute;
  right: 19px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: right 350ms cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
.header .bar .right .disclosures {
  display: flex;
  margin-right: 5px;
}
.header .bar .right .disclosures .disclosure {
  margin-left: 10px;
}
.header .bar .right .disclosures .disclosure .disclosure__toggle {
  border-radius: 50px;
  border: 2px solid;
  padding: 5px 10px;
}
.header .bar .right .disclosures .disclosure .disclosure__toggle .shopify-select-icon {
  fill:  #2a2d32;
}
.header .bar .right .disclosures .disclosure__list {
  right: 0;
  bottom: auto;
  margin-top: 10px;
}
.header .bar .right .disclosures .disclosure__list li {
  text-align: right;
}
.header .bar .right > a {
  text-decoration: none;
  display: block;
  margin-left: 10px;
  line-height: 0;
}
.header .bar .right > a i {
  color: #2a2d32;
  font-size: 30px;
}
.header .bar .right > a .circle {
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 13px;
  color: #2a2d32;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  background: #a3be55;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  font-size: 13px;
  line-height: 26px;
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.header .bar .right > a .circle.hidden-count {
  display: none;
}
.header .nav {
  opacity: 1;
  transition: opacity 350ms cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
.header.show-background .background {
  background-color: #ffffff;
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 0;
}
.header.show-background.showing-megamenu .background {
  background-color: #ffffff;
}
.header.expanded .background {
  top: 0;
  left: 0;
  right: 0;
}
.header .main-nav {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 0 30px 10px;
}
.header .main-nav ul {
  list-style: none;
  padding-top: 8px;
}
.header .main-nav ul li {
  position: relative;
  display: inline-block;
}
.header .main-nav ul li a {
  display: inline-block;
  padding: 10px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
}
.header .main-nav ul li a:hover span, .header .main-nav ul li a.active span, .header .main-nav ul li a.hovered span {
  border-bottom: 2px solid #a3be55;
}
.header .main-nav ul li a.plus.active span {
  border-bottom: 0;
}
.header .main-nav ul li a.plus:after {
  content: '+';
  display: inline-block;
  margin-left: 3px;
}
.header .main-nav ul li ul.tab {
  visibility: hidden;
  transform: translateY(-4%);
  position: absolute;
  z-index: 10;
  left: -5px;
  padding: 5px;
  text-align: left;
  background: white;
  border: 1px solid #eee;
  opacity: 0;
  transition: transform 125ms 0ms, opacity 125ms 0ms, visibility;
}
.header .main-nav ul li ul.tab.right {
  left: auto;
  right: -5px;
  text-align: right;
}
.header .main-nav ul li ul.tab.expanded {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: visibility 0ms 0ms, opacity 83.33333333ms 0ms, transform .25s 0ms, box-shadow 83.33333333ms 62.5ms;
}
.header .main-nav ul li ul.tab li {
  display: block;
  white-space: nowrap;
}
.header .main-nav ul li ul.tab li a {
  color: #404752;
}
.header .main-nav ul li ul.tab li ul li a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  padding-top: 0;
}
.header .main-nav ul li ul.tab li ul li a:hover span, .header .main-nav ul li ul.tab li ul li a.active span {
  border-bottom-width: 1px;
}
.header .main-nav ul li:hover a.plus span {
  border-bottom: 2px solid #a3be55;
}
.header .main-nav ul li.megamenu {
  position: static;
}
.header .main-nav ul li.megamenu ul.tab {
  left: 0;
  right: 0;
  padding: 0;
  border: 0;
  text-align: left;
  background: #ffffff;
}
.header .main-nav ul li.megamenu ul.tab .line {
  border-bottom: 1px solid #eee;
  height: 10px;
  width: 100%;
}
.header .main-nav ul li.megamenu ul.tab .columns {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  margin: 30px 0;
}
.header .main-nav ul li.megamenu ul.tab .columns.space-between {
  justify-content: space-between;
  margin: 30px;
}
.header .main-nav ul li.megamenu ul.tab li a {
  color: #a3be55;
  font-weight: 700;
  font-size: 15px;
}
.header .main-nav ul li.megamenu ul.tab li ul li a {
  color: #2a2d32;
  font-weight: 500;
}
.header .main-nav ul li.megamenu ul.tab .image {
  max-width: 300px;
}
.header .main-nav ul li.megamenu ul.tab .image a {
  padding: 0;
  display: block;
}
.header .main-nav ul li.megamenu ul.tab .image img {
  width: 100%;
  display: block;
}
.header .main-nav ul li.megamenu ul.tab .image p {
  text-align: center;
  margin-top: 4px;
}
.header .top-banner {
  position: relative;
  z-index: 5;
  text-align: center;
}
.header .top-banner .bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #a3be55;
  opacity: 1;
  transition: opacity 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
body.browsing .header .top-banner .bg, body.shopping .header .top-banner .bg, body.picking .header .top-banner .bg {
  opacity: 0.75;
}
.header .top-banner .text {
  position: relative;
}
.header .top-banner .text a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.header .top-banner .text p {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  color: #ffffff;
  padding: 12px 30px;
}
.header.scrolled {
  z-index: 5;
}
.header.scrolled .bar {
  opacity: 0;
}
.header.scrolled .bar .right .disclosures {
  display: none;
}
.header.scrolled .bar.fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1;
  display: block;
}
.header.scrolled .nav {
  opacity: 0;
}
.header.scrolling .fixed .left {
  left: 50px;
}
.header.scrolling .fixed .right {
  right: 50px;
}
.header.solid .fixed {
  background-color: #ffffff;
}
.header.solid .fixed .left {
  left: 30px;
}
.header.solid .fixed .right {
  right: 30px;
}
.header.solid .fixed .center a.logo h1 {
  font-size: 30px;
  color: #404752;
  line-height: 30px;
}

.no-js .header .main-nav ul li:focus-within ul.tab {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: visibility 0ms 0ms, opacity 83.33333333ms 0ms, transform .25s 0ms, box-shadow 83.33333333ms 62.5ms;
}
.no-js .header .header .bar .left a {
  display: none;
}

h1.logo {
  font-size: 40px;
  color: #404752;
  line-height: 40px;
  word-wrap: break-word;
  transition: font-size 350ms cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
h1.logo img {
  max-width: 100%;
  display: block;
  transition: width 350ms cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}

body.template-customers-login .header .bar .right a.icon-cart, body.template-customers-login .header .bar .right a.icon-user,
body.template-customers-account .header .bar .right a.icon-cart,
body.template-customers-account .header .bar .right a.icon-user,
body.template-customers-addresses .header .bar .right a.icon-cart,
body.template-customers-addresses .header .bar .right a.icon-user,
body.template-customers-register .header .bar .right a.icon-cart,
body.template-customers-register .header .bar .right a.icon-user,
body.template-customers-order .header .bar .right a.icon-cart,
body.template-customers-order .header .bar .right a.icon-user,
body.template-404 .header .bar .right a.icon-cart,
body.template-404 .header .bar .right a.icon-user {
  display: none;
}
body.template-customers-login .header .bar .right a.icon-search,
body.template-customers-account .header .bar .right a.icon-search,
body.template-customers-addresses .header .bar .right a.icon-search,
body.template-customers-register .header .bar .right a.icon-search,
body.template-customers-order .header .bar .right a.icon-search,
body.template-404 .header .bar .right a.icon-search {
  padding-right: 8px;
}

body.template-cart .header .bar .right a.icon-cart, body.template-cart .header .bar .right a.icon-user {
  display: none;
}
body.template-cart .header .bar .right a.icon-search {
  padding-right: 8px;
}

.no-js .header .main-nav ul li:hover ul.tab {
  visibility: visible;
  opacity: 1;
  transform: none;
}

@media (max-width: 960px) {
  .header .bar {
    padding-left: 20px;
    padding-right: 20px;
  }
  .header .bar .left {
    left: 14px;
  }
  .header .bar .right {
    right: 14px;
  }
  .header .bar .right .disclosures {
    display: none;
  }
  .header .bar .right a .circle {
    width: 26px;
    height: 26px;
    line-height: 26px;
  }
  .header .bar .right a.icon-user {
    display: none;
  }
  .header.show-background .background {
    left: 20px;
    right: 20px;
    bottom: 0;
  }
  .header.expanded .background {
    top: 0;
    left: 0;
    right: 0;
  }
  .header.scrolling .fixed .left {
    left: 40px;
  }
  .header.scrolling .fixed .right {
    right: 40px;
  }
  .header.solid .fixed .left {
    left: 20px;
  }
  .header.solid .fixed .right {
    right: 20px;
  }
  .header .main-nav ul li.megamenu ul.tab .columns {
    flex-wrap: wrap;
    justify-content: space-evenly !important;
    margin: 20px 20px 0 !important;
  }
  .header .main-nav ul li.megamenu ul.tab .columns .column {
    margin-bottom: 20px;
  }

  h1.logo {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width: 620px) {
  .header .bar {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header .bar .left {
    left: 10px;
  }
  .header .bar .left a span {
    display: none;
  }
  .header .bar .right {
    right: 10px;
  }
  .header .bar .right a .circle {
    width: 26px;
    height: 26px;
    line-height: 26px;
  }
  .header .bar .right a.icon-cart {
    display: inline;
  }
  .header .bar .right a.icon-cart i.fc-cart {
    display: none;
  }
  .header .bar .right a.icon-cart .circle {
    margin-left: 0;
  }
  .header.show-background .background {
    left: 15px;
    right: 15px;
  }
  .header.expanded .background {
    top: 0;
    left: 0;
    right: 0;
  }
  .header .main-nav {
    display: none;
  }
  .header .main-nav.show-on-mobile {
    display: block;
    padding-bottom: 20px;
  }
  .header .main-nav.show-on-mobile ul {
    padding-top: 0;
  }
  .header .main-nav.show-on-mobile ul.right {
    left: auto;
    right: -5px;
    text-align: right;
  }
  .header .main-nav.show-on-mobile ul li ul {
    padding: 5px;
  }
  .header .top-banner .text p {
    padding: 12px 15px;
  }
  .header.scrolling .fixed .left {
    left: 10px;
  }
  .header.scrolling .fixed .right {
    right: 10px;
  }
  .header.solid .fixed .left {
    left: 15px;
  }
  .header.solid .fixed .right {
    right: 15px;
  }

  h1.logo {
    font-size: 30px;
    line-height: 30px;
  }
}
/* Index */
.template-index .layout > .inner {
  position: relative;
}
.template-index .layout > .inner > .sections {
  position: relative;
  z-index: 2;
}

/* Featured products */
.featured-products .featured-product {
  position: relative;
  margin: 0;
  padding: 30px;
  width: 100%;
  height: 600px;
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.featured-products .featured-product .container {
  max-width: 1100px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.featured-products .featured-product a.info {
  opacity: 0;
  display: block;
  text-align: left;
  position: absolute;
  left: 60px;
  right: 60px;
  top: 0;
  bottom: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
  text-decoration: none;
}
.shaped .featured-products .featured-product a.info, .no-js .featured-products .featured-product a.info {
  opacity: 1;
}
.featured-products .featured-product a.info .container {
  height: 100%;
}
.featured-products .featured-product a.info .container .left {
  width: 50%;
  height: 100%;
  display: inline-block;
}
.featured-products .featured-product a.info .container .left .table {
  display: table;
  table-layout: fixed;
  max-width: 500px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.featured-products .featured-product a.info .container .left .table .cell {
  display: table-cell;
  vertical-align: middle;
}
.featured-products .featured-product a.info .container .left .table .cell .title {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 65px;
  line-height: 66px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
}
.featured-products .featured-product a.info .container .left .table .cell .title.mobile {
  display: none;
}
.featured-products .featured-product a.info .container .left .table .cell .price {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 40px;
  color: #2a2d32;
  color:  #a3be55;
  margin-top: 10px;
}
.featured-products .featured-product a.info .container .left .table .cell .price .striped {
  display: inline-block;
  text-decoration: line-through;
  font-size: 30px;
}
.featured-products .featured-product a.info .container .left .table .cell .unit {
  display: block;
  margin-top: 5px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 22px;
  color: #2a2d32;
  color: #2a2d32;
}
.featured-products .featured-product a.info .container .left .table .cell .button {
  margin: 30px 0 0;
}
.featured-products .featured-product .image {
  width: 100%;
  height: 100%;
}
.featured-products .featured-product .image .container:after {
  display: table;
  content: '';
  clear: both;
}
.featured-products .featured-product .image .container .right {
  height: 100%;
  width: 50%;
  overflow: hidden;
  float: right;
}
.featured-products .featured-product .image .container .right .picture {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.featured-products .featured-product .image .container .right .placeholder-svg {
  transform: scale(1.25);
}
.featured-products .featured-product .image .container .right img {
  display: inline-block;
  height: 100%;
}
.featured-products .featured-product.with-overlap a.info .container .left {
  width: 60%;
}
.featured-products .featured-product.with-overlap .image .container .right {
  width: 60%;
}
.shaped .featured-products.active .featured-product .info, .no-js .featured-products.active .featured-product .info {
  opacity: 0;
}
.featured-products.active .featured-product.active .info {
  position: fixed;
  z-index: 1000;
  left: 90px;
  right: 90px;
  bottom: auto;
  height: 600px;
}
.shaped .featured-products.active .featured-product.active .info, .no-js .featured-products.active .featured-product.active .info {
  opacity: 1;
}
.featured-products.active .featured-product.active.absolute {
  position: relative;
}
.featured-products.active .featured-product.active.absolute .info {
  position: absolute;
  left: 60px;
  right: 60px;
}
.featured-products.active.expanded .featured-product.active .info {
  left: 40px;
  right: 40px;
}
.featured-products.active.expanded .featured-product.active.absolute .info {
  left: 20px;
  right: 20px;
}
.featured-products.with_separation .featured-product {
  margin-bottom: 30px;
}
.featured-products.with_separation .featured-product:last-child {
  margin-bottom: 0;
}

@media (max-width: 960px) {
  .featured-products .featured-product a.info {
    left: 40px;
    right: 40px;
  }
  .featured-products .featured-product a.info .container .left .table .cell .title {
    font-size: 55px;
  }
  .featured-products .featured-product a.info .container .left .table .cell .button {
    margin: 30px 0 0;
  }
  .featured-products.active .featured-product.active .info {
    left: 60px;
    right: 60px;
  }
  .featured-products.active .featured-product.active.absolute .info {
    left: 40px;
    right: 40px;
  }
  .featured-products.active.expanded .featured-product.active .info {
    left: 40px;
    right: 40px;
  }
  .featured-products.active.expanded .featured-product.active.absolute .info {
    left: 20px;
    right: 20px;
  }
  .featured-products.with_separation .featured-product {
    margin-bottom: 20px;
  }
}
@media (max-width: 620px) {
  .featured-products .featured-product {
    padding: 0;
    height: auto !important;
  }
  .featured-products .featured-product .image {
    height: 300px;
  }
  .featured-products .featured-product .image .container .right {
    float: none;
    width: 100% !important;
  }
  .featured-products .featured-product a.info {
    opacity: 1;
    position: relative !important;
    left: auto;
    right: auto;
    top: auto !important;
    height: auto;
    width: 100%;
    padding: 15px 0;
  }
  .featured-products .featured-product a.info .container {
    height: auto;
    width: 100%;
  }
  .featured-products .featured-product a.info .container .left {
    width: 100% !important;
    height: auto;
  }
  .featured-products .featured-product a.info .container .left .table {
    width: 100%;
    height: auto;
  }
  .featured-products .featured-product a.info .container .left .table .cell {
    text-align: center;
    padding: 15px 15px 0;
  }
  .featured-products .featured-product a.info .container .left .table .cell .title {
    font-size: 36px;
    line-height: 40px;
    display: inline;
  }
  .featured-products .featured-product a.info .container .left .table .cell .price {
    font-size: 30px;
    line-height: 34px;
    display: inline-block;
  }
  .featured-products .featured-product a.info .container .left .table .cell .title {
    display: none;
  }
  .featured-products .featured-product a.info .container .left .table .cell .title.mobile {
    display: block;
  }
  .featured-products .featured-product a.info .container .left .table .cell .button {
    margin: 30px auto 0px;
  }
}
/* Featured image */
.featured-image img {
  display: block;
  width: 100%;
}
.featured-image a {
  text-decoration: none;
}
.featured-image .placeholder-svg {
  width: 100%;
  height: 40vw;
  display: block;
  background-color: #f3f0ee;
}

/* Text columns with images */
.text-columns-with-images {
  text-align: center;
}
.text-columns-with-images .rte {
  margin-bottom: 30px;
}
.text-columns-with-images .rte p {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 20px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 400;
  text-transform: none;
}
.text-columns-with-images .cols {
  font-size: 0;
  max-width: 1200px;
  margin: 0 auto;
}
.text-columns-with-images .cols:after {
  display: table;
  content: '';
  clear: both;
}
.text-columns-with-images .cols .col {
  min-width: 100px;
  padding: 15px;
  margin: 0;
  display: inline-block;
  vertical-align: top;
}
.text-columns-with-images .cols .col a {
  text-decoration: none;
  display: block;
}
.text-columns-with-images .cols .col img {
  display: inline-block;
  margin: 0 0 10px;
  width: 100%;
}
.text-columns-with-images .cols .col .icon {
  width: 85px;
  height: 85px;
  display: inline-block;
  margin: 0 0 10px;
}
.text-columns-with-images .cols .col .icon i {
  font-size: 85px;
  color: #a3be55;
}
.text-columns-with-images .cols .col .number {
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 50px;
  color: #2a2d32;
  color: #a3be55;
  margin: 0 auto 10px;
  border: 3px solid #a3be55;
  width: 69px;
  height: 74px;
  border-radius: 65px;
  padding: 5px 5px 0;
  box-sizing: content-box;
}
.text-columns-with-images .cols .col h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 20px;
  line-height: 23px;
  color: #2a2d32;
  color: #2a2d32;
  margin: 0 0 4px;
}
.text-columns-with-images .cols .col p {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 16px;
  line-height: 22px;
  color: #2a2d32;
  color: #2a2d32;
  text-transform: none;
  margin: 0 auto;
}
.text-columns-with-images .cols .col p a {
  text-decoration: underline;
}
.text-columns-with-images .cols.layout-1 .col {
  width: 100%;
}
.text-columns-with-images .cols.layout-2 .col {
  width: 50%;
}
.text-columns-with-images .cols.layout-3 .col {
  width: 33.3333333333%;
}
.text-columns-with-images .cols.layout-4 .col {
  width: 25%;
}
.text-columns-with-images .cols.layout-5 .col {
  width: 20%;
}
.text-columns-with-images .no-content {
  margin: 30px;
}
.text-columns-with-images.left .cols .col {
  text-align: left;
}
.text-columns-with-images.right .cols .col {
  text-align: right;
}

@media (max-width: 820px) {
  .text-columns-with-images .row.cols.layout-4 .col {
    width: 50%;
  }
  .text-columns-with-images .row.cols.layout-5 .col, .text-columns-with-images .row.cols.layout-6 .col {
    width: 33.3333333333%;
  }
}
@media (max-width: 620px) {
  .text-columns-with-images .row.cols .col {
    min-width: 0;
    padding: 15px;
  }
  .text-columns-with-images .row.cols .col .number {
    margin: 0 auto 10px;
    border-radius: 50px;
    box-sizing: content-box;
  }
  .text-columns-with-images .row.cols .col h2 {
    font-size: 18px;
  }
  .text-columns-with-images .row.cols .col p {
    font-size: 14px;
    line-height: 18px;
  }
  .text-columns-with-images .row.cols.layout-3 .col, .text-columns-with-images .row.cols.layout-4 .col, .text-columns-with-images .row.cols.layout-5 .col, .text-columns-with-images .row.cols.layout-6 .col {
    width: 50%;
  }
  .text-columns-with-images.with-padding {
    margin: 0 15px;
  }
}
.map {
  position: relative;
}
.map:after {
  display: table;
  content: '';
  clear: both;
}
.map > .background {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}
.map > .background .image {
  width: 100%;
  height: 100%;
  background: #ffffff;
  background-size: cover;
}
.map > .background .map-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.map > .background .map-container .warning {
  display: none;
  padding: 20px;
  margin: 20px;
  background: #a3be55;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  line-height: 22px;
  color: #2a2d32;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
}
.map > .background .map-container.error .warning {
  display: block;
}
.map > .info {
  z-index: 2;
  position: relative;
  padding: 30px;
  margin: 60px 30px;
  background: rgba(255, 255, 255, 0.85);
  float: left;
  width: 100%;
}
.map > .info h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 40px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  word-wrap: break-word;
}
.map > .info p {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  line-height: 25px;
  color: #2a2d32;
  min-height: 16px;
}
.map > .info p b, .map > .info p strong {
  font-weight: 700;
}
.map > .info p a {
  text-decoration: underline;
}
.map > .info p:first-child {
  margin-top: 10px;
}
.map > .info p:last-child {
  margin-bottom: 20px;
}

@media (max-width: 960px) {
  .map > .info {
    padding: 0 0 15px;
    margin: 0;
    background: #ffffff;
    text-align: center;
    max-width: none !important;
  }
  .map > .info p {
    font-size: 22px;
    line-height: 32px;
  }
  .map > .background {
    position: static;
    width: 100%;
    margin: 0 0 15px;
  }
  .map > .background .image, .map > .background .map-container {
    height: 300px;
    overflow: hidden;
  }
}
/* Newsletter */
.newsletter {
  background-color: #f3f0ee !important;
}
.newsletter .rte {
  text-align: center;
  margin: 0 auto;
  padding: 0 0 30px;
}
.newsletter .rte p {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 20px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 400;
  text-transform: none;
  margin-top: 10px;
}
.newsletter .rte p a {
  color: #2a2d32;
  text-decoration: underline;
}
.newsletter .rte p a:hover {
  border: 0;
}
.newsletter form {
  max-width: 540px;
  margin: 0 auto;
}
.newsletter form .table {
  display: table;
  width: 100%;
}
.newsletter form .table input.text {
  padding: 16px 15px 15px;
  display: table-cell;
  width: 100%;
}
.newsletter form .table span.input-group-btn {
  display: table-cell;
  width: 1%;
  vertical-align: top;
}
.newsletter form .table span.input-group-btn .btn {
  padding: 14px 20px;
  margin-left: 5px;
  margin-right: 10px;
}
.newsletter form .field-error {
  margin-left: 15px;
}
.newsletter form .success {
  text-align: center;
  color: #a3be55;
  font-size: 18px;
}

.image-with-text .flex-row .image {
  flex: 0 0 50%;
}
.image-with-text .flex-row .info {
  padding-left: 40px;
}
.image-with-text .flex-row .info h2 {
  margin-top: 0px;
  color: #a3be55;
}
.image-with-text .flex-row.odd .image {
  order: 2;
}
.image-with-text .flex-row.odd .info {
  padding-left: 0;
  padding-right: 40px;
}
@media (max-width: 960px) {
  .image-with-text .flex-row .image {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .image-with-text .flex-row .info {
    padding: 0;
  }
  .image-with-text .flex-row.odd .image {
    order: 0;
  }
  .image-with-text .flex-row.odd .info {
    padding: 0;
  }
}

.banner-with-text {
  position: relative;
  overflow: hidden;
}
.banner-with-text:after {
  display: table;
  content: '';
  clear: both;
}
.banner-with-text > .background {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.banner-with-text > .background .image {
  width: 100%;
  height: 100%;
  background: #ffffff;
  background-size: cover;
}
.banner-with-text > .background .placeholder-svg {
  transform: scale(-1.8) rotate(180deg);
  background-color: #f3f0ee;
}
.banner-with-text > .info {
  z-index: 2;
  position: relative;
  padding: 30px;
  background: rgba(255, 255, 255, 0.85);
  float: left;
  width: 100%;
}
.banner-with-text > .info h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 40px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  word-wrap: break-word;
}
.banner-with-text > .info p {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  line-height: 25px;
  color: #2a2d32;
  text-transform: none;
  margin: 0px 0 20px 2px;
}
.banner-with-text > .info p b, .banner-with-text > .info p strong {
  font-weight: FontDrop;
}
.banner-with-text > .info p a:not(.btn) {
  text-decoration: underline;
}
.banner-with-text > .info p:nth-child(2) {
  margin-top: 20px;
}
.banner-with-text > .info p:last-child {
  margin-bottom: 0;
}
.banner-with-text.position-right > .info {
  float: right;
}
.banner-with-text.position-center > .info {
  float: none;
  margin: 0 auto;
}
.banner-with-text.align-center {
  text-align: center;
}
.banner-with-text.align-right {
  text-align: right;
}

@media (max-width: 620px) {
  .banner-with-text > .info {
    padding: 15px;
    margin: 0;
    background: #ffffff;
    text-align: center;
    max-width: none !important;
    width: 100%;
  }
  .banner-with-text > .info p {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 14px;
    line-height: 27px;
    color: #2a2d32;
  }
  .banner-with-text > .background {
    position: static;
    width: 100%;
  }
  .banner-with-text > .background .image {
    height: 200px;
  }
  .banner-with-text > .background .placeholder-svg {
    transform: scale(1) rotate(180deg);
  }
  .banner-with-text.position-right > .info {
    float: none;
  }
}
.logo-list .flex-row {
  flex-wrap: wrap;
}
.logo-list p.category {
  text-align: center;
}
.logo-list .logo {
  width: 100px;
  display: block;
  margin: 20px;
}
.logo-list .placeholder-svg {
  fill: #2a2d32;
}
@media (max-width: 960px) {
  .logo-list .flex-row {
    flex-direction: row;
  }
}

.call-to-action .flex-row {
  align-items: stretch;
}
.call-to-action .banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #a3be55;
}
.call-to-action .banner a {
  text-decoration: none;
  display: block;
  border: 0 !important;
}
.call-to-action .banner .info {
  padding: 50px;
}
.call-to-action .banner h2 {
  color: #ffffff;
  margin: 0 0 20px;
  line-height: 34px;
}
.call-to-action .banner h6 {
  color: #ffffff;
  margin: 0;
  text-decoration: underline;
  font-weight: normal;
  font-size: 14px;
}
.call-to-action .banner:nth-child(even) {
  background: #2a2d32;
}
.call-to-action .banner:nth-child(even) h2, .call-to-action .banner:nth-child(even) h6 {
  color:  #ffffff;
}
@media (max-width: 960px) {
  .call-to-action h2 {
    font-size: 24px;
    line-height: 26px;
  }
  .call-to-action h6 {
    font-size: 14px;
  }
}

/* Featured video */
.featured-video .video {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: auto;
}
.featured-video .video.vimeo {
  padding-bottom: 47.4%;
}
.featured-video .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

/* Rich text */
.section-rich-text .inner.centered .rte {
  text-align: center;
}
.section-rich-text .rte p {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 20px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 400;
  text-transform: none;
}
.section-rich-text .rte p a {
  color: #2a2d32;
  text-decoration: underline;
}
.section-rich-text .rte p a:hover {
  border: 0;
}
.section-rich-text .rte.with-header p {
  margin-top: 10px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slideshow .inner.small {
  height: 400px;
}
.slideshow .inner.medium {
  height: 550px;
}
.slideshow .inner.large {
  height: 700px;
}
.slideshow .slick-list, .slideshow .slick-track {
  height: 100%;
}
.slideshow .slick-slide {
  height: 100%;
  width: 100%;
}
.slideshow .slick-slide > div {
  height: 100%;
  width: 100%;
}
.slideshow .slide {
  display: none;
  height: 100%;
  position: relative;
}
.slideshow .slide a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 2;
}
.slideshow .slide a:focus {
  outline: none;
  border: 2px solid #005fcc;
  border-radius: 10px;
}
.slideshow .slide .slideshow-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.slideshow .slide .slideshow-image .table {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  z-index: 2;
  position: relative;
}
.slideshow .slide .slideshow-image .table .cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0 50px;
}
.slideshow .slide .slideshow-image .table .cell h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 65px;
  line-height: 66px;
  color: #2a2d32;
  color: #333333;
  font-weight: 700;
  margin: 0 0 10px;
}
.slideshow .slide .slideshow-image .table .cell h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 30px;
  line-height: 31px;
  color: #2a2d32;
  color: #a3be55;
  margin: 0 0 20px;
}
.slideshow .slide .placeholder-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.slideshow .slide.is-light .slideshow-image .table .cell h1 {
  color: #ffffff;
}
.slideshow .slide.is-light .slideshow-image .table .cell .btn {
  color: #ffffff;
  border-color: #ffffff;
}
.slideshow .slide.is-light .slideshow-image .table .cell .btn:hover {
  color: #cccccc;
  border-color: #cccccc;
}
.slideshow .slide.is-light .placeholder-background .placeholder-svg {
  fill: #333333;
  background-color: #262626;
}
.slideshow .slide.is-dark .slideshow-image .table .cell h1 {
  color: #333333;
}
.slideshow .slide.is-dark .slideshow-image .table .cell .btn {
  color: #333333;
  border-color: #333333;
}
.slideshow .slide.is-dark .slideshow-image .table .cell .btn:hover {
  color: #666666;
  border-color: #666666;
}
.slideshow .slide.is-dark .placeholder-background .placeholder-svg {
  background-color: #f3f0ee;
}
.slideshow .slide.left {
  text-align: left;
}
.slideshow .slide.right {
  text-align: right;
}
.slideshow .slide.center {
  text-align: center;
}
.slideshow .slide:first-child {
  display: block;
}
.slideshow.slick-initialized div {
  display: block;
}
.slideshow .slick-prev, .slideshow .slick-next {
  font-size: 0;
  line-height: 40px;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  background: transparent;
}
.slideshow .slick-prev:before, .slideshow .slick-next:before {
  color: #333333;
  font-family: 'FontColors';
  font-size: 40px;
}
.slideshow .slick-prev.is-light:before, .slideshow .slick-next.is-light:before {
  color: #ffffff;
}
.slideshow .slick-prev {
  left: 20px;
  z-index: 2;
}
.slideshow .slick-prev:before {
  content: "G";
}
.slideshow .slick-next {
  right: 20px;
}
.slideshow .slick-next:before {
  content: "F";
}
.slideshow .slick-dots {
  position: absolute;
  bottom: 30px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}
.slideshow .slick-dots li {
  display: inline-block;
}
.slideshow .slick-dots li button {
  font-size: 0;
  padding: 0 4px;
  color: #333333;
  cursor: pointer;
}
.slideshow .slick-dots li button:before {
  font-family: 'FontColors';
  font-size: 20px;
  content: "B";
}
.slideshow .slick-dots li.slick-active button:before {
  content: "A";
}
.slideshow .slick-dots.is-light li button {
  color: #ffffff;
}

@media (max-width: 960px) {
  .slideshow .slide .slideshow-image .table .cell {
    padding: 0 20px;
  }
}
@media (max-width: 620px) {
  .slideshow .inner.small {
    height: 300px;
  }
  .slideshow .inner.medium {
    height: 450px;
  }
  .slideshow .inner.large {
    height: 600px;
  }
  .slideshow .slide .slideshow-image .table .cell {
    padding: 0;
  }
  .slideshow .slide .slideshow-image .table .cell h1 {
    font-size: 45px;
    line-height: 50px;
  }
  .slideshow .slide .slideshow-image .table .cell h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .slideshow .slick-dots {
    bottom: 10px;
  }
  .slideshow .slick-dots li button:before {
    font-size: 20px;
  }
  .slideshow .slick-prev {
    left: 0px;
  }
  .slideshow .slick-next {
    right: 0px;
  }
}
/* List collections */
.collections-items {
  margin-left: -10px;
  margin-top: -10px;
}
.collections-items:after {
  display: table;
  content: '';
  clear: both;
}
.collections-items a.collections-item {
  position: relative;
  text-decoration: none;
  display: block;
  float: left;
  width: 33.333%;
  height: 240px;
  padding-left: 10px;
  padding-top: 10px;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
}
.collections-items a.collections-item.one-whole {
  width: 100%;
  height: 340px;
}
.collections-items a.collections-item.one-whole.short {
  height: 240px;
}
.collections-items a.collections-item.one-whole.tall {
  height: 440px;
}
.collections-items a.collections-item.one-half {
  width: 50%;
  height: 300px;
}
.collections-items a.collections-item.one-half.short {
  height: 200px;
}
.collections-items a.collections-item.one-half.tall {
  height: 400px;
}
.collections-items a.collections-item.one-third {
  width: 33.333%;
  height: 280px;
}
.collections-items a.collections-item.one-third.short {
  height: 180px;
}
.collections-items a.collections-item.one-third.tall {
  height: 380px;
}
.collections-items a.collections-item h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 26px;
  color: #2a2d32;
  position: absolute;
  bottom: 20px;
  max-width: 175px;
  display: inline;
  z-index: 2;
  word-wrap: break-word;
}
.collections-items a.collections-item.left h1 {
  left: 25px;
}
.collections-items a.collections-item.center h1 {
  left: 10px;
  right: 0;
  text-align: center;
  max-width: none;
  width: 100%;
}
.collections-items a.collections-item.right h1 {
  right: 15px;
  left: 15px;
  max-width: none;
  text-align: right;
}
.collections-items a.collections-item.with-underline h1 {
  text-decoration: underline;
}
.collections-items a.collections-item .image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.collections-items a.collections-item .image.opacity-0 {
  opacity: 0;
}
.collections-items a.collections-item .image.opacity-10 {
  opacity: 0.1;
}
.collections-items a.collections-item .image.opacity-20 {
  opacity: 0.2;
}
.collections-items a.collections-item .image.opacity-30 {
  opacity: 0.3;
}
.collections-items a.collections-item .image.opacity-40 {
  opacity: 0.4;
}
.collections-items a.collections-item .image.opacity-50 {
  opacity: 0.5;
}
.collections-items a.collections-item .image.opacity-60 {
  opacity: 0.6;
}
.collections-items a.collections-item .image.opacity-70 {
  opacity: 0.7;
}
.collections-items a.collections-item .image.opacity-80 {
  opacity: 0.8;
}
.collections-items a.collections-item .image.opacity-90 {
  opacity: 0.9;
}
.collections-items a.collections-item .image.opacity-100 {
  opacity: 1;
}
.collections-items a.collections-item .placeholder-svg {
  width: 100%;
  height: 100%;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
  background-color: #f3f0ee;
}
.collections-items a.collections-item .placeholder-svg.opacity-0 {
  opacity: 0;
}
.collections-items a.collections-item .placeholder-svg.opacity-10 {
  opacity: 0.1;
}
.collections-items a.collections-item .placeholder-svg.opacity-20 {
  opacity: 0.2;
}
.collections-items a.collections-item .placeholder-svg.opacity-30 {
  opacity: 0.3;
}
.collections-items a.collections-item .placeholder-svg.opacity-40 {
  opacity: 0.4;
}
.collections-items a.collections-item .placeholder-svg.opacity-50 {
  opacity: 0.5;
}
.collections-items a.collections-item .placeholder-svg.opacity-60 {
  opacity: 0.6;
}
.collections-items a.collections-item .placeholder-svg.opacity-70 {
  opacity: 0.7;
}
.collections-items a.collections-item .placeholder-svg.opacity-80 {
  opacity: 0.8;
}
.collections-items a.collections-item .placeholder-svg.opacity-90 {
  opacity: 0.9;
}
.collections-items a.collections-item .placeholder-svg.opacity-100 {
  opacity: 1;
}
.collections-items a.collections-item.contain {
  padding-bottom: 50px;
}
.collections-items a.collections-item.contain .image {
  background-size: contain;
}
.collections-items a.collections-item.cover .image {
  background-size: cover;
}
.collections-items.interactive a.collections-item h1 {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.collections-items.interactive a.collections-item .image.opacity-0, .collections-items.interactive a.collections-item .placeholder-svg.opacity-0 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-10, .collections-items.interactive a.collections-item .placeholder-svg.opacity-10 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-20, .collections-items.interactive a.collections-item .placeholder-svg.opacity-20 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-30, .collections-items.interactive a.collections-item .placeholder-svg.opacity-30 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-40, .collections-items.interactive a.collections-item .placeholder-svg.opacity-40 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-50, .collections-items.interactive a.collections-item .placeholder-svg.opacity-50 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-60, .collections-items.interactive a.collections-item .placeholder-svg.opacity-60 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-70, .collections-items.interactive a.collections-item .placeholder-svg.opacity-70 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-80, .collections-items.interactive a.collections-item .placeholder-svg.opacity-80 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-90, .collections-items.interactive a.collections-item .placeholder-svg.opacity-90 {
  opacity: 1;
}
.collections-items.interactive a.collections-item .image.opacity-100, .collections-items.interactive a.collections-item .placeholder-svg.opacity-100 {
  opacity: 1;
}
.collections-items.interactive a.collections-item:hover h1 {
  opacity: 1;
}
.collections-items.interactive a.collections-item:hover .image.opacity-0, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-0 {
  opacity: 0;
}
.collections-items.interactive a.collections-item:hover .image.opacity-10, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-10 {
  opacity: 0.1;
}
.collections-items.interactive a.collections-item:hover .image.opacity-20, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-20 {
  opacity: 0.2;
}
.collections-items.interactive a.collections-item:hover .image.opacity-30, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-30 {
  opacity: 0.3;
}
.collections-items.interactive a.collections-item:hover .image.opacity-40, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-40 {
  opacity: 0.4;
}
.collections-items.interactive a.collections-item:hover .image.opacity-50, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-50 {
  opacity: 0.5;
}
.collections-items.interactive a.collections-item:hover .image.opacity-60, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-60 {
  opacity: 0.6;
}
.collections-items.interactive a.collections-item:hover .image.opacity-70, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-70 {
  opacity: 0.7;
}
.collections-items.interactive a.collections-item:hover .image.opacity-80, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-80 {
  opacity: 0.8;
}
.collections-items.interactive a.collections-item:hover .image.opacity-90, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-90 {
  opacity: 0.9;
}
.collections-items.interactive a.collections-item:hover .image.opacity-100, .collections-items.interactive a.collections-item:hover .placeholder-svg.opacity-100 {
  opacity: 1;
}

@media (max-width: 960px) {
  .collections-items a.collections-item.one-whole, .collections-items a.collections-item.one-half, .collections-items a.collections-item.one-third {
    width: 50%;
    height: 200px;
  }
  .collections-items a.collections-item.one-whole.short, .collections-items a.collections-item.one-half.short, .collections-items a.collections-item.one-third.short {
    height: 100px;
  }
  .collections-items a.collections-item.one-whole.tall, .collections-items a.collections-item.one-half.tall, .collections-items a.collections-item.one-third.tall {
    height: 340px;
  }

  .template-index .collections-items a.collections-item:first-child {
    width: 100%;
    height: 300px;
  }
  .template-index .collections-items a.collections-item:first-child.short {
    height: 200px;
  }
  .template-index .collections-items a.collections-item:first-child.tall {
    height: 400px;
  }
}
@media (max-width: 620px) {
  .collections-items a.collections-item.one-whole, .collections-items a.collections-item.one-half, .collections-items a.collections-item.one-third {
    width: 100%;
    height: 200px;
  }
  .collections-items a.collections-item.one-whole.short, .collections-items a.collections-item.one-half.short, .collections-items a.collections-item.one-third.short {
    height: 100px;
  }
  .collections-items a.collections-item.one-whole.tall, .collections-items a.collections-item.one-half.tall, .collections-items a.collections-item.one-third.tall {
    height: 340px;
  }
  .collections-items a.collections-item h1 {
    bottom: 10px;
  }

  .featured-collections {
    padding: 30px 15px 0 5px;
  }

  .content.list-collections .collections-items {
    padding: 0 15px;
  }
  .content.list-collections .collections-items a.collections-item {
    padding-left: 0;
  }
  .content.list-collections .collections-items a.collections-item.left h1 {
    left: 10px;
  }
  .content.list-collections .collections-items a.collections-item.center h1 {
    left: 0;
  }
  .content.list-collections .collections-items a.collections-item.right h1 {
    right: 10px;
  }

  .template-index .collections-items a.collections-item {
    width: 100% !important;
    height: 200px !important;
  }
  .template-index .collections-items a.collections-item:first-child {
    width: 100% !important;
    height: 200px !important;
  }
}
/* Collection */
.template-search .content {
  padding-left: 20px;
}
.template-search .content .search-header {
  padding-left: 10px;
}

.collection .rte, .collection .image, .collection ul.categories, .collection .sorting-and-filtering {
  margin: 0 0 20px;
}
.collection .image img {
  width: 100%;
}

.collection-products {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  margin-left: -10px;
}
.collection-products h3.empty {
  display: block;
  width: 100%;
}
.collection-products .collection-product {
  display: block;
  position: relative;
  text-decoration: none;
  width: 25%;
  padding-left: 10px;
  margin-bottom: 10px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
}
.collection-products .collection-product.two {
  width: 50%;
}
.collection-products .collection-product.three {
  width: 33.33333%;
}
.collection-products .collection-product > .inner {
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.collection-products .collection-product > .inner .image {
  width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
}
.collection-products .collection-product > .inner .image.square {
  padding-bottom: 100%;
}
.collection-products .collection-product > .inner .image.portrait {
  padding-bottom: 125%;
}
.collection-products .collection-product > .inner .image.landscape {
  padding-bottom: 75%;
}
.collection-products .collection-product > .inner .image img {
  position: absolute;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  object-position: center center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.collection-products .collection-product > .inner .image img + img {
  transition: transform 500ms ease;
  opacity: 0;
}
.collection-products .collection-product > .inner .image .placeholder-svg {
  max-width: 100%;
  padding: 0;
  margin: 0 auto;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.collection-products .collection-product > .inner .table {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 150px;
  background-color: #ffffff;
}
.collection-products .collection-product > .inner .table .cell {
  display: table-cell;
  vertical-align: middle;
}
.collection-products .collection-product > .inner .tag {
  position: absolute;
  top: 10px;
  right: 10px;
}
.collection-products .collection-product > .inner .tag .solid {
  text-align: center;
  color: white;
  background: #a3be55;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 13px;
  color: #2a2d32;
  color: #ffffff;
  font-weight: 700;
}
.collection-products .collection-product > .inner .tag .solid.sold-out {
  background: #353333;
  color: #ffffff;
}
.collection-products .collection-product > .inner .tag .solid.sale {
  color: #ffffff;
  background: #a3be55;
}
.no-js .collection-products .collection-product > .inner .tag {
  display: none;
}
.collection-products .collection-product > .inner .info {
  width: 100%;
  position: relative;
}
.collection-products .collection-product > .inner .info .metas {
  padding: 10px;
}
.collection-products .collection-product > .inner .info .metas:after {
  display: table;
  content: '';
  clear: both;
}
.collection-products .collection-product > .inner .info .metas .title {
  display: inline;
  max-width: 100%;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
}
.collection-products .collection-product > .inner .info .metas .subtitle {
  display: block;
  margin-top: 10px;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
  font-size: 13px;
}
.collection-products .collection-product > .inner .info .metas .price {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
  color: #a3be55;
}
.collection-products .collection-product > .inner .info .metas .price.striped {
  text-decoration: line-through;
  display: inline;
  font-size: 13px;
}
.collection-products .collection-product > .inner .info .metas .title, .collection-products .collection-product > .inner .info .metas .price {
  font-size: 15px;
  line-height: 17px;
}
.collection-products .collection-product > .inner .info .metas .unit {
  display: block;
  margin-bottom: 2px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
  font-size: 13px;
  color: #2a2d32;
}
.collection-products .collection-product > .inner .info .metas .vendor {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  font-size: 15px;
  line-height: 19px;
  margin-top: 2px;
}
.collection-products .collection-product.opened {
  cursor: default;
}
.collection-products .collection-product.dummie > .inner .image {
  background: #f3f0ee;
}
.collection-products .collection-product.text-only .inner {
  padding: 100px 0;
  background: #ececec;
  justify-content: center;
}
.collection-products .collection-product:hover .image img + img, .collection-products .collection-product.opened .image img + img {
  transform: scale(1.03);
  opacity: 1;
}
.collection-products .content.product {
  margin-left: 10px;
  margin-bottom: 10px;
}
.collection-products .pagination {
  text-align: center;
  padding-left: 10px;
}
.collection-products:not(.with-secondary).hover-opacity-0 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-0 .collection-product.opened .inner .image img {
  opacity: 0;
}
.collection-products:not(.with-secondary).hover-opacity-0.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-0.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-0.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-0.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-10 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-10 .collection-product.opened .inner .image img {
  opacity: 0.1;
}
.collection-products:not(.with-secondary).hover-opacity-10.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-10.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-10.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-10.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0.9;
}
.collection-products:not(.with-secondary).hover-opacity-20 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-20 .collection-product.opened .inner .image img {
  opacity: 0.2;
}
.collection-products:not(.with-secondary).hover-opacity-20.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-20.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-20.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-20.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0.8;
}
.collection-products:not(.with-secondary).hover-opacity-30 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-30 .collection-product.opened .inner .image img {
  opacity: 0.3;
}
.collection-products:not(.with-secondary).hover-opacity-30.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-30.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-30.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-30.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0.7;
}
.collection-products:not(.with-secondary).hover-opacity-40 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-40 .collection-product.opened .inner .image img {
  opacity: 0.4;
}
.collection-products:not(.with-secondary).hover-opacity-40.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-40.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-40.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-40.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0.6;
}
.collection-products:not(.with-secondary).hover-opacity-50 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-50 .collection-product.opened .inner .image img {
  opacity: 0.5;
}
.collection-products:not(.with-secondary).hover-opacity-50.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-50.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-50.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-50.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0.5;
}
.collection-products:not(.with-secondary).hover-opacity-60 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-60 .collection-product.opened .inner .image img {
  opacity: 0.6;
}
.collection-products:not(.with-secondary).hover-opacity-60.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-60.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-60.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-60.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0.4;
}
.collection-products:not(.with-secondary).hover-opacity-70 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-70 .collection-product.opened .inner .image img {
  opacity: 0.7;
}
.collection-products:not(.with-secondary).hover-opacity-70.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-70.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-70.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-70.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0.3;
}
.collection-products:not(.with-secondary).hover-opacity-80 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-80 .collection-product.opened .inner .image img {
  opacity: 0.8;
}
.collection-products:not(.with-secondary).hover-opacity-80.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-80.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-80.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-80.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0.2;
}
.collection-products:not(.with-secondary).hover-opacity-90 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-90 .collection-product.opened .inner .image img {
  opacity: 0.9;
}
.collection-products:not(.with-secondary).hover-opacity-90.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-90.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-90.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-90.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0.1;
}
.collection-products:not(.with-secondary).hover-opacity-100 .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-100 .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-100.with-gradients .collection-product:hover .inner .image img, .collection-products:not(.with-secondary).hover-opacity-100.with-gradients .collection-product.opened .inner .image img {
  opacity: 1;
}
.collection-products:not(.with-secondary).hover-opacity-100.with-gradients .collection-product:hover .inner .image .gradient, .collection-products:not(.with-secondary).hover-opacity-100.with-gradients .collection-product.opened .inner .image .gradient {
  opacity: 0;
}
.collection-products.with-interactions .collection-product .inner {
  position: relative;
}
.collection-products.with-interactions .collection-product .inner .image .gradient {
  left: 0;
}
.collection-products.with-interactions .collection-product .inner .info {
  position: absolute;
  bottom: 3px;
  right: 5px;
  left: 5px;
  width: auto;
  overflow: hidden;
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.collection-products.with-interactions .collection-product .inner .info .metas .title, .collection-products.with-interactions .collection-product .inner .info .metas .subtitle, .collection-products.with-interactions .collection-product .inner .info .metas .price {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
}
.collection-products.with-interactions .collection-product .inner .info .metas .title, .collection-products.with-interactions .collection-product .inner .info .metas .price {
  font-size: 24px;
  line-height: 26px;
}
.collection-products.with-interactions .collection-product .inner .info .metas .title {
  display: inline-block;
  max-width: 190px;
}
.collection-products.with-interactions .collection-product .inner .info .metas .subtitle {
  font-size: 13px;
}
.collection-products.with-interactions .collection-product .inner .info .metas .price {
  color: #a3be55;
  margin-bottom: 0;
}
.collection-products.with-interactions .collection-product .inner .info .metas .price.striped {
  font-size: 18px;
}
.collection-products.with-interactions .collection-product .inner .info .metas .unit {
  font-size: 16px;
}
.collection-products.with-interactions .collection-product.opened:hover .inner .info {
  opacity: 0;
}
.collection-products.with-interactions .collection-product:hover .inner .info {
  opacity: 1;
}
.collection-products.with-gradients .inner .info .metas .price {
  color: #ffffff;
}
.collection-products.with-gradients .collection-product .inner .image .gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 0;
  opacity: 0;
  background: linear-gradient(to bottom, rgba(163, 190, 85, 0) 0%, #a3be55 100%);
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.collection-products.with-gradients.with-interactions .collection-product .inner .image .gradient {
  left: 0;
}
.collection-products.without-title .collection-product .inner .info .title {
  display: none !important;
}
.collection-products.without-price .collection-product .inner .info .price, .collection-products.without-price .collection-product .inner .info .unit {
  display: none !important;
}
.collection-products.without-vendor .collection-product .inner .info .vendor {
  display: none !important;
}
.collection-products.with-title-underline .collection-product .inner .info .metas .title {
  text-decoration: underline;
}
.collection-products.without-title.without-price.without-vendor .collection-product .inner .info {
  display: none !important;
}
.collection-products.align-left .collection-product .inner .info .metas {
  text-align: left;
}
.collection-products.align-center .collection-product .inner .info .metas {
  text-align: center;
}
.collection-products.align-right .collection-product .inner .info .metas {
  text-align: right;
}
.collection-products.align-one-line .collection-product .inner .info .metas {
  text-align: left;
}
.collection-products.align-one-line .collection-product .inner .info .metas .line {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.collection-products.align-one-line .collection-product .inner .info .metas .line .collection-product-title .title {
  display: block;
}
.collection-products.align-one-line .collection-product .inner .info .metas .line .collection-product-price {
  order: 2;
  text-align: right;
}
.collection-products.align-one-line.with-interactions .collection-product .inner .info .metas .line {
  align-items: flex-end;
}
.collection-products.align-one-line.with-interactions .collection-product .inner .info .metas .line .collection-product-title {
  display: flex;
  flex-direction: column;
}
.collection-products.align-one-line.with-interactions .collection-product .inner .info .metas .line .collection-product-title .title {
  order: 2;
}
.collection-products.align-one-line.with-interactions .collection-product .inner .info .metas .line .collection-product-price {
  display: flex;
  flex-direction: column;
}
.collection-products.align-one-line.with-interactions .collection-product .inner .info .metas .line .collection-product-price .price {
  order: 2;
}
.collection-products.no-padding:not(.with-interactions):not(.with-gradients) .collection-product .inner .info .metas {
  padding: 5px 5px 0 0;
}

@media (max-width: 960px) {
  .collection-products .collection-product {
    width: 33.33%;
  }

  .product-related-products .collection-products .collection-product:nth-child(4n) {
    display: none;
  }
}
@media (max-width: 620px) {
  .template-collection .content {
    padding: 30px 0 0;
  }

  .collection {
    padding: 0 15px 20px 5px;
  }
  .collection a.root, .collection h2.divider {
    display: none;
  }

  .collection-products .collection-product {
    width: 50%;
  }
  .collection-products .collection-product.three {
    width: 50%;
  }
  .collection-products .collection-product:nth-child(4n) {
    display: block;
  }
  .collection-products.with-interactions .collection-product .inner .info .metas .title, .collection-products.with-interactions .collection-product .inner .info .metas .price {
    font-size: 20px;
    line-height: 24px;
  }

  .no-js .product-related-products {
    display: none;
  }
  .product-related-products .collection-products .collection-product:nth-child(4n) {
    display: block;
  }

  .template-search .content {
    padding-left: 5px;
  }
}
/* Side Nav */
.side-nav {
  position: relative;
}
.side-nav > a {
  display: none;
}
.side-nav .inner {
  position: fixed;
  z-index: 400;
  top: 0;
  left: -400px;
  max-width: 400px;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #a3be55;
  overflow-y: auto;
}
.side-nav .close {
  position: absolute;
  top: 17px;
  left: 30px;
}
.side-nav .close i.fc {
  color: #ffffff;
  font-size: 28px;
}
.side-nav .menu ul {
  list-style: none;
  margin: 37px 0 0;
}
.side-nav .menu ul li a {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 34px;
  color: #2a2d32;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
  padding: 10px 0;
  display: inline-block;
}
.side-nav .menu ul li a:hover {
  text-decoration: underline;
}
.side-nav .menu ul li a.plus:hover {
  text-decoration: none;
}
.side-nav .menu ul li a.plus:hover span {
  text-decoration: underline;
}
.side-nav .menu ul li a.plus:after {
  content: '+';
  margin-left: 10px;
  display: inline-block;
  transition: transform 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
  font-weight: 500;
}
.side-nav .menu ul li a.plus[aria-expanded="true"]:after {
  transform: rotate(45deg);
}
.side-nav .menu ul ul {
  display: none;
  margin: 0 0 10px;
}
.side-nav .menu ul ul li {
  margin: 0;
  padding-left: 15px;
}
.side-nav .menu ul ul li a {
  font-size: 24px;
  font-weight: 500;
  padding: 5px 0 5px;
}
.side-nav .menu ul ul ul li a {
  font-size: 20px;
}
.side-nav .extra > * {
  padding-top: 20px;
}
.side-nav .extra > *:first-child {
  margin: 30px 0 0;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.side-nav .extra > *:last-child {
  padding-bottom: 30px;
}
.side-nav .selectors-form .disclosures .disclosure {
  display: inline-block;
  margin-right: 8px;
}
.side-nav .selectors-form .disclosures .disclosure:last-child {
  margin-right: 0;
}
.side-nav .selectors-form .disclosures .disclosure .disclosure__toggle {
  color: #ffffff;
  border-color: #ffffff;
  background: transparent;
}
.side-nav .selectors-form .disclosures .disclosure .disclosure__toggle .shopify-select-icon {
  fill:  #ffffff;
}
.side-nav .connect {
  font-size: 35px;
}
.side-nav .connect a {
  color: #ffffff;
}

.no-js .side-nav {
  height: 50px;
  z-index: 200;
  width: 50px;
  position: absolute;
}
.no-js .side-nav > a {
  display: block;
  position: absolute;
  left: 14px;
  top: 17px;
}
.no-js .side-nav > a i {
  color: #2a2d32;
  font-size: 30px;
  vertical-align: middle;
}
.no-js .side-nav > a span {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  margin-left: 4px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.no-js .side-nav .inner .menu ul li:focus-within > ul, .no-js .side-nav .inner .menu ul li:hover > ul {
  display: block;
}
.no-js .side-nav .inner .menu ul li:focus-within > ul li:focus-within ul, .no-js .side-nav .inner .menu ul li:focus-within > ul li:hover ul, .no-js .side-nav .inner .menu ul li:hover > ul li:focus-within ul, .no-js .side-nav .inner .menu ul li:hover > ul li:hover ul {
  display: block;
}
.no-js .side-nav .inner .close {
  display: none;
}
.no-js .side-nav:focus-within .inner, .no-js .side-nav:hover .inner {
  left: 0;
}

@media (max-width: 960px) {
  .side-nav .inner {
    padding: 20px;
  }
  .side-nav .close {
    left: 20px;
  }
}
@media (max-width: 620px) {
  .side-nav .inner {
    padding: 15px;
  }
  .side-nav .close {
    top: 17px;
    left: 15px;
  }
  .side-nav .menu ul {
    margin: 37px 0 0;
  }
}
/* Side Cart */
.side-cart {
  visibility: hidden;
  color: #ffffff;
  background: #2a2d32;
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  max-width: 100%;
  height: 100%;
  text-align: left;
  padding: 17px 30px 17px;
}
.side-cart .side-cart-header {
  border-bottom: 3px solid #ffffff;
}
.side-cart .side-cart-header h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 25px;
  color: #2a2d32;
  color: #ffffff;
  font-weight: 700;
  margin: 0 0 20px;
}
.side-cart .side-cart-header h5 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
}
.side-cart .close {
  position: absolute;
  top: 17px;
  right: 30px;
  text-decoration: none;
}
.side-cart .close i.fc {
  color: #ffffff;
  font-size: 28px;
  position: relative;
  right: -4px;
  top: 3px;
}
.side-cart .side-cart-container {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 30px;
}
.side-cart .side-cart-container .items {
  position: absolute;
  top: 0;
  bottom: 150px;
  left: 0;
  right: 0;
  padding: 0 30px;
  overflow-y: auto;
  overflow-x: hidden;
}
.side-cart .side-cart-container .items .item {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.side-cart .side-cart-container .items .item .quantity {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.side-cart .side-cart-container .items .item.loading .quantity {
  opacity: 0.3;
}
.side-cart .side-cart-container .items .item.removing {
  opacity: 0.1;
}
.side-cart .side-cart-container .more .cart__checkout {
  border-radius: 5px;
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05);
  padding: 16px;
}
.side-cart .side-cart-container .more .additional-checkout-buttons {
  padding-bottom: 20px;
  margin-top: -20px;
}
.side-cart .side-cart-container > h3 {
  margin-top: 10px;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-ms-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 420px) {
  .side-cart {
    padding: 17px 15px 17px;
  }
  .side-cart .close {
    right: 15px;
  }
  .side-cart .side-cart-container {
    padding: 15px 15px;
  }
  .side-cart .side-cart-container .items {
    padding: 0 15px;
  }
  .side-cart .side-cart-container .more .additional-checkout-buttons {
    padding-bottom: 20px;
  }
  .side-cart .side-cart-container .cart.negative .more {
    left: 15px;
    right: 15px;
  }
}
.side-pick {
  visibility: hidden;
  color: #ffffff;
  background: #2a2d32;
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  max-width: 100%;
  height: 100%;
  text-align: left;
  padding: 17px 30px 17px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.side-pick .side-pick-header {
  border-bottom: 3px solid #ffffff;
  padding-right: 35px;
  padding-bottom: 15px;
}
.side-pick .side-pick-header h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 25px;
  color: #2a2d32;
  color: #ffffff;
  font-weight: 700;
  margin: 0 0 5px;
}
.side-pick .side-pick-header h3 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
}
.side-pick .close {
  position: absolute;
  top: 17px;
  right: 30px;
  text-decoration: none;
}
.side-pick .close i.fc {
  color: #ffffff;
  font-size: 28px;
  position: relative;
  right: -4px;
  top: 3px;
}
.side-pick .locations {
  overflow-y: auto;
  overflow-x: hidden;
}
.side-pick .locations .location {
  padding: 20px 0;
  border-bottom: 1px solid #ffffff;
}
.side-pick .locations .location:last-child {
  /*border-bottom: 0;*/
}
.side-pick .locations .location h4 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 20px;
  color: #2a2d32;
  font-weight: 700;
  color: #ffffff;
}
.side-pick .locations .location .availability {
  font-style: italic;
  margin: 8px 0;
  font-size: 14px;
}
.side-pick .locations .location .availability .icon {
  margin: 0 4px 0 0;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  top: -2px;
  padding: 5px;
}
.side-pick .locations .location .availability .icon path {
  fill: #ffffff;
}
.side-pick .locations .location .availability .icon.icon-in-stock {
  background: #00730B;
}
.side-pick .locations .location .availability .icon.icon-out-of-stock {
  background: #DD2200;
}
.side-pick .locations .location .address {
  margin: 0;
}
.side-pick .locations .location .address p {
  color: #ffffff;
  font-size: 12px;
  line-height: 18px;
}
.side-pick .locations .location .address p.phone a {
  color: #ffffff;
  text-decoration: none;
}
.side-pick .locations .location .address p.phone a:hover {
  border-bottom: 1px solid;
}

@media (max-width: 420px) {
  .side-pick {
    padding: 17px 15px 17px;
  }
  .side-pick .close {
    right: 15px;
  }
}
/* Cart */
.cart:after {
  display: table;
  content: '';
  clear: both;
}
.cart .items .item {
  position: relative;
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid #2a2d32;
}
.cart .items .item:after {
  display: table;
  content: '';
  clear: both;
}
.cart .items .item .left {
  float: left;
  width: 20%;
}
.cart .items .item .left img {
  width: 100%;
  background: #ffffff;
}
.cart .items .item .right {
  float: left;
  width: 80%;
  padding: 0 30px 0 10px;
}
.cart .items .item .right a.name {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  line-height: 26px;
  color: #2a2d32;
  color: #a3be55;
  text-decoration: underline;
  display: inline;
  position: relative;
  top: -4px;
}
.cart .items .item .right .description {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  color: #2a2d32;
  margin: 0 0 6px;
}
.cart .items .item .right .description .unit-price {
  display: block;
  margin-top: 5px;
  font-size: 14px;
}
.cart .items .item .right .quantity {
  margin: 10px 0 0;
}
.cart .items .item .right .quantity input {
  width: 50px;
  text-align: center;
  border: 1px solid;
  background-color: transparent;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  color: #2a2d32;
  padding: 4px 0;
}
.cart .items .item .right .quantity button {
  color: #2a2d32;
  background-color: transparent;
  border: 0;
  padding: 4px 0;
  width: 30px;
  cursor: pointer;
}
.cart .items .item .price {
  position: absolute;
  bottom: 30px;
  right: 0;
  text-align: right;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 20px;
  color: #2a2d32;
  color: #2a2d32;
}
.cart .items .item .price.with-discount {
  position: static;
}
.cart .items .item .price .striped {
  text-decoration: line-through;
}
.cart .items .item .price .accent {
  color: #a3be55;
}
.cart .items .item .price .allocation {
  display: block;
  margin: 2px 0;
  font-size: 16px;
  color: #a3be55;
}
.cart .items .item .price .allocation .icon {
  vertical-align: middle;
  margin-right: 5px;
}
.cart .items .item .remove {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  text-decoration: none;
}
.cart .items .item .remove i.fc {
  color: #595f69;
}
.cart .items .item.last {
  border-bottom: 0;
}
.cart .instructions {
  float: left;
  width: 50%;
}
.cart .instructions label {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  display: block;
  margin: 0 0 10px;
}
.cart .instructions textarea {
  min-height: 100px;
  width: 100%;
}
.cart .more {
  border-top: 3px solid #2a2d32;
  padding-top: 10px;
}
.cart .more h3 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 30px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
}
.cart .more h3:after {
  display: table;
  content: '';
  clear: both;
}
.cart .more h3 .value {
  float: right;
  text-align: right;
}
.cart .more h3.small {
  font-size: 20px;
}
.cart .more h4 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  font-style: italic;
  display: block;
  margin: 0 0 10px;
}
.cart .more h5 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  font-weight: 700;
  display: block;
  margin: 0 0 5px;
}
.cart .more h5 .icon {
  vertical-align: middle;
  margin-right: 5px;
  font-weight: 600;
  position: relative;
  top: -1px;
}
.cart .more h5 .value {
  float: right;
  text-align: right;
}
.cart .more button.update {
  display: none;
}
.no-js .cart .more button {
  display: inline-block;
}
.no-js .cart .more button br {
  display: inline-block;
  margin-right: 4px;
  content: ' ';
}
.cart.negative .items .item {
  border-color: #ffffff;
  padding-bottom: 20px;
}
.cart.negative .items .item:last-child {
  border: 0;
}
.cart.negative .items .item .left {
  width: 30%;
}
.cart.negative .items .item .right {
  width: 70%;
  padding-bottom: 10px;
}
.cart.negative .items .item .right .description {
  color: #ffffff;
}
.cart.negative .items .item .right .description del {
  opacity: 0.7;
}
.cart.negative .items .item .right .quantity input {
  color: #ffffff;
}
.cart.negative .items .item .right .quantity button {
  color: #ffffff;
}
.cart.negative .items .item .price {
  color: #ffffff;
  text-align: right;
  position: static;
  clear: both;
}
.cart.negative .items .item .price .striped {
  display: block;
  opacity: 0.7;
}
.cart.negative .items .item .price .discounted {
  display: block;
}
.cart.negative .items .item .price .allocation {
  margin-left: auto;
  line-height: 22px;
  margin-bottom: 0;
}
.cart.negative .items .item .price .unit-price {
  display: block;
  font-size: 14px;
  opacity: 0.7;
}
.cart.negative .items .item .remove i.fc {
  color: #cccccc;
}
.cart.negative .instructions {
  float: none;
  width: 100%;
  margin: 30px 0;
}
.cart.negative .instructions textarea {
  min-height: 160px;
}
.cart.negative .more {
  border-color: #ffffff;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  padding-bottom: 30px;
  background: #2a2d32;
}
.cart.negative .more h3 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 25px;
  color: #2a2d32;
  color: #ffffff;
  font-weight: 700;
}
.cart.negative .more h3 span {
  font-weight: 300;
}
.cart.negative .more h4 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  display: block;
  margin: 0 0 10px;
}
.cart.negative .more h5 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
  color: #a3be55;
  display: block;
  margin: 0 0 5px;
}
.cart.negative .more h5 .icon {
  position: static;
  margin-right: 2px;
}
.cart.negative .more button {
  width: 100%;
}

body.template-cart .cart {
  max-width: 800px;
}
body.template-cart .cart .additional-checkout-buttons {
  margin: 20px 0 0;
}
body.template-cart .cart .additional-checkout-buttons [data-shopify-buttoncontainer] {
  justify-content: flex-end;
}

body.template-customers-order .cart .items .item {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #2a2d32;
}
body.template-customers-order .cart .items .item.last {
  border-bottom: 0;
}
body.template-customers-order .cart .items .item .left {
  max-width: 100px;
}
body.template-customers-order .cart .items .item .right {
  padding-left: 20px;
}
body.template-customers-order .cart .items .item .right .description {
  color: #2a2d32 !important;
}
body.template-customers-order .cart .items .item .price {
  font-size: 20px;
  bottom: 20px;
}
body.template-customers-order .cart .items .item .allocation {
  color: #a3be55;
  max-width: 400px;
  display: inline-block;
}
body.template-customers-order .cart .items .item .allocation .icon {
  vertical-align: middle;
  margin-right: 5px;
}
body.template-customers-order .cart .more h3.with-line {
  border-width: 1px;
}
body.template-customers-order .cart .more h3.discount {
  color: #a3be55;
}
body.template-customers-order .cart .more h3.discount .icon {
  vertical-align: middle;
  margin-right: 5px;
  font-weight: bold;
}

@media (max-width: 960px) {
  body.template-cart .instructions {
    width: 100%;
  }
}
@media (max-width: 1100px) {
  body.template-customers-order .cart .items .item .price {
    position: static;
  }
}
/* Account */
.template-customers-account .row.orders .order p {
  max-width: 450px;
}
.template-customers-account .row.orders .order p a span.highlight {
  color: #a3be55;
}
.template-customers-account .row.orders hr {
  margin: 15px 0;
  background: #2a2d32;
}

/* Account Addresses */
.addresses .pagination {
  padding: 0 0 40px;
  text-align: left;
}

.address {
  margin: 0 0 40px;
}
.address p {
  color: #2a2d32;
}
.address .options {
  margin: 15px 0 40px;
}
.address .options .boxed {
  margin-right: 5px;
}
.address .options i.fc {
  color: #a3be55;
  font-size: 24px;
  display: inline-block;
  position: relative;
  top: 7px;
  margin: 0 4px;
}

/* Pages */
.section-page .placeholder-svg {
  display: block;
  max-width: 100%;
  max-height: 300px;
}

/* Search */
.search .section-header {
  display: flex;
  align-items: baseline;
}
.search .section-header .search-bar-wrapper {
  display: inline-block;
  width: 100%;
}
.search .section-header .search-bar-wrapper form {
  display: inline-block;
  width: 100%;
}
.search .section-header .search-bar-wrapper form input {
  display: inline-block;
  width: 100%;
  padding-right: 4px;
}
.search .section-header .search-bar-wrapper form input::-webkit-clear-button, .search .section-header .search-bar-wrapper form input::-webkit-search-cancel-button {
  display: none;
}
.search .search-products > h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 29px;
  color: #2a2d32;
  color: #2a2d32;
  display: block;
  margin: 20px 0;
}
.search .search-products .sorting-and-filtering {
  margin: 0 0 20px;
}
.search .search-empty {
  margin: 0 0 0 10px;
}

.search-overlay {
  visibility: hidden;
  position: fixed;
  z-index: 1500;
  left: 0;
  top: -100% !important;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #ffffff;
}
.search-overlay .search-bar-wrapper {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
.search-overlay .search-bar-wrapper form {
  padding: 40px 30px;
}
.search-overlay .search-bar-wrapper form input.text {
  width: 100%;
  background: transparent;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 60px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
}
.search-overlay .search-bar-wrapper form input.text::-webkit-clear-button, .search-overlay .search-bar-wrapper form input.text::-webkit-search-cancel-button {
  display: none;
}
.search-overlay .search-bar-wrapper .predictive-search-results {
  width: 100%;
  height: 0;
  padding: 0 30px;
  display: none;
}
.searching .search-overlay .search-bar-wrapper .predictive-search-results {
  height: auto;
  display: block;
}
.search-overlay .close {
  position: absolute;
  top: 40px;
  right: 32px;
  text-decoration: none;
}
.search-overlay .close i.fc {
  font-size: 30px;
  color: #2a2d32;
}

@media (max-width: 960px) {
  .search-overlay .search-bar-wrapper .predictive-search-results .collection-products .collection-product {
    width: 50%;
  }
  .search-overlay .search-bar-wrapper .predictive-search-results .collection-products .collection-product.col-1, .search-overlay .search-bar-wrapper .predictive-search-results .collection-products .collection-product.col-3 {
    clear: none;
  }
  .search-overlay .search-bar-wrapper .predictive-search-results .collection-products .collection-product.col-0, .search-overlay .search-bar-wrapper .predictive-search-results .collection-products .collection-product.col-2 {
    clear: left;
  }
}
@media (max-width: 620px) {
  .search .search-header .search-bar-wrapper form input {
    margin: 20px 0;
    width: 100%;
  }
}
/* Blog */
.blog .excerpts:after {
  display: table;
  content: '';
  clear: both;
}
.blog .excerpts .post {
  margin: 0 0 60px;
}
.blog .excerpts .post:last-child {
  margin-bottom: 0;
}
.blog .article > .comments {
  padding: 30px 0 0;
}
.blog .article > .comments .comment {
  margin: 0 0 30px;
}
.blog .article > .comments .comment h4 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 17px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  margin: 0 0 14px;
}
.blog .article > .comments .comment h5 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  margin: 0 0 8px;
}
.blog .article > .comments .comment h6 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 17px;
  line-height: 30px;
  color: #2a2d32;
  color: #2a2d32;
  margin: 0 0 14px;
}
.blog .article > .comments h2 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
}
.blog .article > .comments #comment_form {
  padding-top: 25px;
}
.blog .article > .comments #comment_form input, .blog .article > .comments #comment_form textarea {
  border: 1px solid #595f69;
}
.blog .article > .comments h4 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  font-style: italic;
}
.blog h2.empty {
  padding-left: 30px;
}
.blog.grid .excerpts {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
}
.blog.grid .excerpts .post {
  width: 50%;
  margin: 0 0 40px;
  padding-left: 30px;
}
.blog.grid .excerpts .post:nth-last-child(-n+2) {
  margin: 0;
}

.post.col-0 {
  clear: left;
}
.post .image {
  margin: 0 0 20px;
  width: 100%;
}
.post .image-preview {
  margin: 0 0 20px;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.post h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 40px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  word-wrap: break-word;
}
.post h1 a {
  text-decoration: none;
  color: #2a2d32
}
.post h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  line-height: 22px;
  color: #2a2d32;
  text-transform: none;
  margin: 10px 0 30px 2px;
}
.post .btn.more {
  margin-top: 20px;
}
.post hr {
  margin: 0 0 30px;
}
.post .extra {
  margin: 20px 0 10px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
  color: #2a2d32;
}
.post .extra .comments {
  display: inline-block;
  margin-right: 14px;
}
.post .extra .comments a {
  text-decoration: none;
  color: #2a2d32;
}
.post .extra .comments a:hover span {
  text-decoration: underline;
}
.post .extra .tags {
  display: inline-block;
}
.post .extra .tags a {
  text-decoration: none;
  color: #2a2d32;
  margin: 0 4px;
}
.post .extra .tags a:hover {
  text-decoration: underline;
}
.post .extra .icon {
  color: #a3be55;
  font-size: 22px;
  display: inline-block;
  margin-right: 2px;
  vertical-align: middle;
}

@media (max-width: 960px) {
  .blog .excerpts {
    margin-left: 0;
  }
  .blog .excerpts .post.grid {
    float: none;
    width: 100%;
    margin: 0 0 40px;
    padding-right: 0;
    padding-left: 0;
  }
  .blog .excerpts .post.grid:nth-last-child(-n+2) {
    margin: 0 0 40px;
  }
  .blog .excerpts .post:last-child {
    margin-bottom: 0;
  }
  .blog.grid .excerpts {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
  .blog.grid .excerpts .post {
    width: 100%;
    padding-left: 0;
  }
}
@media (max-width: 620px) {
  .blog .excerpts .post {
    margin: 0 0 15px !important;
  }
  .blog h2.empty {
    padding-left: 15px;
  }
}
/* Product */
.product {
  position: relative;
  /* blocks */
}
.product:after {
  display: table;
  content: '';
  clear: both;
}
.product .item {
  width: 100%;
}
.product .item:after {
  display: table;
  content: '';
  clear: both;
}
.product .item > .left, .product .item > .right {
  width: 50%;
  float: left;
  min-height: 1px;
}
.product .item > .left .product-media .frame .table {
  display: table;
  table-layout: fixed;
  width: 100%;
  max-height: 350px;
}
.product .item > .left .product-media .frame .table .cell {
  display: table-cell;
  vertical-align: middle;
}
.product .item > .left .product-media .frame .table .cell img {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}
.product .item > .left .product-media .frame .table .cell img[data-mfp-src] {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.product .item > .left .product-media .frame .table .cell img .hide {
  display: none;
}
.product .item > .left .product-media .frame .table .cell .media {
  margin: 0 auto;
  width: 100%;
}
.product .item > .left .product-media .frame .table .cell .media:focus {
  outline: 0;
}
.product .item > .left .product-media .frame .table .cell .media > .inner {
  margin: 0 auto;
  min-height: 1px;
  width: 100%;
  height: 100%;
  position: relative;
}
.product .item > .left .product-media .frame .table .cell .media > .inner.video {
  background-color: rgba(42, 45, 50, 0.05);
}
.product .item > .left .product-media .frame .table .cell .media > .inner iframe,
.product .item > .left .product-media .frame .table .cell .media > .inner model-viewer,
.product .item > .left .product-media .frame .table .cell .media > .inner .shopify-model-viewer-ui,
.product .item > .left .product-media .frame .table .cell .media > .inner img,
.product .item > .left .product-media .frame .table .cell .media > .inner > video,
.product .item > .left .product-media .frame .table .cell .media > .inner .plyr,
.product .item > .left .product-media .frame .table .cell .media > .inner .media-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
}
.product .item > .left .product-media .frame .table .cell .media > .inner iframe,
.product .item > .left .product-media .frame .table .cell .media > .inner .media-video,
.product .item > .left .product-media .frame .table .cell .media > .inner model-viewer,
.product .item > .left .product-media .frame .table .cell .media > .inner .shopify-model-viewer-ui,
.product .item > .left .product-media .frame .table .cell .media > .inner .media-item,
.product .item > .left .product-media .frame .table .cell .media > .inner .plyr,
.product .item > .left .product-media .frame .table .cell .media > .inner .plyr__video-wrapper {
  height: 100%;
}
.product .item > .left .product-media .frame .table .cell .media > .inner .plyr__video-wrapper {
  height: auto;
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .model-viewer {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__controls-area {
  background: #ffffff;
  border-color: rgba(42, 45, 50, 0.05);
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__button {
  color: #2a2d32;
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__button--control:hover {
  color: rgba(42, 45, 50, 0.55);
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__button--control:active, .product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__button--control.focus-visible:focus {
  color: rgba(42, 45, 50, 0.55);
  background: rgba(42, 45, 50, 0.05);
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__button--control:not(:last-child):after {
  border-color: rgba(42, 45, 50, 0.05);
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__button--poster {
  background: #ffffff;
  border-color: rgba(42, 45, 50, 0.05);
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__button--poster:hover, .product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__button--poster:focus {
  color: rgba(42, 45, 50, 0.55);
}
.product .item > .left .product-media .frame .table .cell .media .shopify-model-viewer-ui .shopify-model-viewer-ui__poster-control-icon {
  display: block;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video {
  color: #2a2d32;
  background-color: transparent;
  outline: none;
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__poster,
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__video-wrapper {
  background-color: transparent;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video:fullscreen .plyr__video-wrapper .product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video:fullscreen .plyr__poster {
  background-color: #000;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video:-webkit-full-screen .plyr__video-wrapper, .product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video:-webkit-full-screen .plyr__poster {
  background-color: #000;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video:-moz-full-screen .plyr__video-wrapper, .product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video:-moz-full-screen .plyr__poster {
  background-color: #000;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video:-ms-fullscreen .plyr__video-wrapper, .product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video:-ms-fullscreen .plyr__poster {
  background-color: #000;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr--fullscreen-fallback .plyr__video-wrapper,
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr--fullscreen-fallback .plyr__poster {
  background-color: #000;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__control.plyr__control--overlaid {
  background-color: #ffffff;
  border-color: rgba(42, 45, 50, 0.05);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__control.plyr__control--overlaid.plyr__tab-focus, .product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__control.plyr__control--overlaid:hover {
  color: rgba(42, 45, 50, 0.55);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__controls {
  background-color: #ffffff;
  border-color: rgba(42, 45, 50, 0.05);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range']::-moz-range-thumb {
  box-shadow: 2px 0 0 0 #ffffff;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range']::-ms-thumb {
  box-shadow: 2px 0 0 0 #ffffff;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range']::-webkit-slider-thumb {
  box-shadow: 2px 0 0 0 #ffffff;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range']::-webkit-slider-runnable-track {
  background-image: linear-gradient(to right, currentColor var(--value, 0), rgba(42, 45, 50, 0.6) var(--value, 0));
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range']::-moz-range-track {
  background-color: rgba(#2a2d32, 0.6);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range']::-ms-fill-upper {
  background-color: rgba(#2a2d32, 0.6);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 4px rgba(42, 45, 50, 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range'].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 4px rgba(42, 45, 50, 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range'].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 4px rgba(42, 45, 50, 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 0 0 3px rgba(42, 45, 50, 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range']:active::-ms-thumb {
  box-shadow: 0 0 0 3px rgba(42, 45, 50, 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(42, 45, 50, 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress .plyr__tooltip {
  background-color: #2a2d32;
  color: #ffffff;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__progress .plyr__tooltip::before {
  border-top-color: #2a2d32;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video.plyr--loading .plyr__progress__buffer {
  background-image: linear-gradient(-45deg, rgba(42, 45, 50, 0.6) 25%, transparent 25%, transparent 50%, rgba(42, 45, 50, 0.6) 50%, rgba(42, 45, 50, 0.6) 75%, transparent 75%, transparent);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range'] {
  color: #ffffff;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range']::-moz-range-thumb {
  box-shadow: 2px 0 0 0 #2a2d32;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range']::-ms-thumb {
  box-shadow: 2px 0 0 0 #2a2d32;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range']::-webkit-slider-thumb {
  box-shadow: 2px 0 0 0 #2a2d32;
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range']::-webkit-slider-runnable-track {
  background-image: linear-gradient(to right, currentColor var(--value, 0), rgba(255, 255, 255, 0.6), 0.6) var(--value, 0));
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range']::-moz-range-track, .product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range']::-ms-fill-upper {
  background-color: rgba(255, 255, 255, 0.6), 0.6);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.6), 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range'].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.6), 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range'].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.6), 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.6), 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range']:active::-ms-thumb {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.6), 0.25);
}
.product .item > .left .product-media .frame .table .cell .media .plyr.plyr--full-ui.plyr--video .plyr__volume input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.6), 0.25);
}
.product .item > .left .product-media .gallery:after {
  display: table;
  content: '';
  clear: both;
}
.product .item > .left .product-media .gallery .thumb {
  width: 25%;
  display: block;
  float: left;
  padding: 10px 0 0 10px;
}
.product .item > .left .product-media .gallery .thumb.col-0 {
  clear: left;
}
.product .item > .left .product-media .gallery .thumb > a {
  text-decoration: none;
}
.product .item > .left .product-media .gallery .thumb > a > div {
  position: relative;
}
.product .item > .left .product-media .gallery .thumb img {
  width: 100%;
  display: block;
}
.product .item > .left .product-media .gallery .thumb .badge {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
}
.product .item > .left .product-media .gallery .thumb .badge .icon {
  fill: #fff;
  width: 100%;
  height: 100%;
  vertical-align: baseline;
}
.product .item > .left .product-media .gallery .thumb .badge .icon-3d-badge-full-color-outline,
.product .item > .left .product-media .gallery .thumb .badge .icon-video-badge-full-color-outline {
  stroke: rgba(42, 45, 50, 0.05);
}
.product .item > .left .product-media .gallery .thumb .badge .icon-3d-badge-full-color-element,
.product .item > .left .product-media .gallery .thumb .badge .icon-video-badge-full-color-element {
  fill: #2a2d32;
}
.product .item > .left .product-media.right .frame, .product .item > .left .product-media.left .frame {
  width: 80%;
}
.product .item > .left .product-media.right .gallery, .product .item > .left .product-media.left .gallery {
  width: 20%;
}
.product .item > .left .product-media.right .gallery .thumb, .product .item > .left .product-media.left .gallery .thumb {
  float: none;
  width: 100%;
  padding: 0;
  margin: 0 0 20px;
}
.product .item > .left .product-media.right .gallery .thumb:last-child, .product .item > .left .product-media.left .gallery .thumb:last-child {
  margin-bottom: 0;
}
.product .item > .left .product-media.right .frame {
  float: left;
}
.product .item > .left .product-media.right .gallery {
  float: left;
  padding-left: 20px;
}
.product .item > .left .product-media.left .frame {
  float: right;
}
.product .item > .left .product-media.left .gallery {
  float: left;
  padding-right: 20px;
}
.product .item > .left .product-media.bottom .gallery {
  margin-left: -10px;
}
.product .item > .left .product-media.stack .frame .table .cell .media {
  display: block;
  margin-bottom: 30px;
}
.product .item > .left .product-media.stack .frame .table .cell .media:last-child {
  margin-bottom: 0;
}
.product .item > .left .product-media.stack .gallery {
  display: none;
}
.product .item > .left .view-in-space {
  background-color: rgba(42, 45, 50, 0.08);
  border: none;
  width: 100%;
  min-height: 44px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  font-size: 14px;
  color: #2a2d32;
}
.product .item > .left .view-in-space[data-shopify-xr-hidden] {
  display: none;
}
.product .item > .left .view-in-space:hover span.text, .product .item > .left .view-in-space:focus span.text {
  border-bottom-color: #2a2d32;
}
.product .item > .left .view-in-space svg.icon {
  height: 25px;
  width: 25px;
  margin-top: -3px;
  margin-right: 2.43px;
  fill: transparent;
  vertical-align: middle;
}
.product .item > .left .view-in-space svg.icon .icon-3d-badge-full-color-outline {
  stroke: none;
}
.product .item > .left .view-in-space svg.icon .icon-3d-badge-full-color-element {
  fill: #2a2d32;
  opacity: 1;
}
.product .item > .left .view-in-space span.text {
  border-bottom: 1px solid transparent;
}
.product .item > .right {
  padding-left: 30px;
}
.product .item > .right a.title {
  text-decoration: none;
  cursor: default;
  display: none;
}
.product .item > .right h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 31px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  display: inline;
}
.product .item > .right h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 22px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
}
.product .item > .right .offers {
  margin-top: -10px;
}
.product .item > .right .offers h2.price {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 35px;
  color: #2a2d32;
  color: #a3be55;
  display: inline-block;
}
.product .item > .right .offers h2.price.striped {
  text-decoration: line-through;
  font-size: 24px;
}
.product .item > .right shopify-payment-terms {
  display: block;
  margin-top: 10px;
}
.product .item > .right .unit-price {
  display: block;
  margin: 4px 0 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 19px;
  color: #2a2d32;
  color: #2a2d32;
}
.product .item > .right input.quantity {
  opacity: 0;
  border: 1px solid;
  padding: 4px;
  width: 80px;
  text-align: center;
}
.no-js .product .item > .right input.quantity {
  opacity: 1;
}
.product .item > .right .js-qty input {
  opacity: 1;
  border: 1px solid;
  padding: 4px 0;
  text-align: center;
  width: 50px;
  -webkit-appearance: none;
}
.product .item > .right .js-qty input:disabled {
  opacity: 0.5 !important;
}
.product .item > .right .js-qty button {
  cursor: pointer;
  padding: 3px 8px;
  touch-action: manipulation;
}
.product .item > .right h3 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
}
.product .item > .right .store-availability .location {
  margin-top: 20px;
  background: #f2f2f2;
  padding: 20px 20px 18px;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
}
.product .item > .right .store-availability .location .icon {
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  padding: 5px;
}
.product .item > .right .store-availability .location .icon path {
  fill: #ffffff;
}
.product .item > .right .store-availability .location .icon.icon-in-stock {
  background: #00730B;
  margin-top: 2px;
}
.product .item > .right .store-availability .location .icon.icon-out-of-stock {
  background: #DD2200;
}
.product .item > .right .store-availability .location .information a {
  display: inline-block;
  text-decoration: underline;
}
.product .item > .right .store-availability .location .information p.link {
  margin-top: 10px;
}
.product .item > .right .radio-wrapper, .product .item > .right .selector-wrapper {
  margin: 10px 0 0;
}
.product .item > .right .radio-wrapper:first-child, .product .item > .right .selector-wrapper:first-child {
  margin-top: 0;
}
.product .item > .right .radio-wrapper > label, .product .item > .right .selector-wrapper > label {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  margin: 0 0 5px;
  display: block;
}
.product .item > .right .radio-wrapper fieldset, .product .item > .right .selector-wrapper fieldset {
  border: 0;
  background: transparent;
}
.product .item > .right .radio-wrapper fieldset input[type="radio"], .product .item > .right .selector-wrapper fieldset input[type="radio"] {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}
.product .item > .right .radio-wrapper fieldset input[type="radio"]:checked + label, .product .item > .right .selector-wrapper fieldset input[type="radio"]:checked + label {
  color: #a3be55;
}
.product .item > .right .radio-wrapper fieldset input[type="radio"]:focus + label, .product .item > .right .selector-wrapper fieldset input[type="radio"]:focus + label {
  color: #015ECC;
}
.no-outlines .product .item > .right .radio-wrapper fieldset input[type="radio"]:focus + label, .no-outlines .product .item > .right .selector-wrapper fieldset input[type="radio"]:focus + label {
  color: #2a2d32;
}
.product .item > .right .radio-wrapper fieldset input[type="radio"]:focus:checked + label, .product .item > .right .selector-wrapper fieldset input[type="radio"]:focus:checked + label {
  color: #015ECC;
}
.no-outlines .product .item > .right .radio-wrapper fieldset input[type="radio"]:focus:checked + label, .no-outlines .product .item > .right .selector-wrapper fieldset input[type="radio"]:focus:checked + label {
  color: #a3be55;
}
.product .item > .right .radio-wrapper fieldset label, .product .item > .right .selector-wrapper fieldset label {
  display: inline-block;
  border: 2px solid;
  margin: 0 5px 10px 0;
  padding: 8px;
  min-width: 35px;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  cursor: pointer;
}
.product .item > .right .radio-wrapper fieldset label.disabled, .product .item > .right .selector-wrapper fieldset label.disabled {
  opacity: 0.25;
  cursor: default;
}
.product .item > .right .expanded .radio-wrapper, .product .item > .right .expanded .selector-wrapper {
  margin-top: 20px;
}
.product .item > .right .add-to-cart,
.product .item > .right .shopify-payment-button__button--unbranded {
  cursor: pointer;
  padding: 14px;
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: normal;
}
.product .item > .right .add-to-cart,
.product .item > .right .shopify-payment-button__button--unbranded,
.product .item > .right .shopify-payment-button__button--branded {
  border-radius: 3px;
  overflow: hidden;
}
.product .item > .right .add-to-cart {
  padding: 14px;
  background: #2a2d32;
  color: #ffffff;
  border-color: #2a2d32;
  transition: background 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.product .item > .right .add-to-cart:hover {
  background: #131416;
}
.product .item > .right .add-to-cart:disabled {
  cursor: default;
  opacity: 0.8;
}
.product .item > .right .add-to-cart:disabled:hover {
  color: #ffffff;
  background: #2a2d32;
  border-color: #2a2d32;
}
.product .item > .right .add-to-cart.with-payment-button {
  padding: 12px;
  color: #2a2d32;
  background: transparent;
  border: 2px solid #2a2d32;
}
.product .item > .right .add-to-cart.with-payment-button:hover {
  color: #131416;
  border-color: #131416;
}
.product .item > .right .add-to-cart.with-payment-button:disabled {
  cursor: default;
}
.product .item > .right .add-to-cart.with-payment-button:disabled:hover {
  color: #2a2d32;
  background: transparent;
  border-color: #2a2d32;
}
.product .item > .right .shopify-payment-button {
  margin-top: 10px;
}
.product .item > .right .add-to-cart:disabled + .shopify-payment-button {
  display: none;
}
.product .item > .right .shopify-payment-button__button--branded {
  min-height: 50px;
}
.product .item > .right .shopify-payment-button__button--unbranded {
  background: #2a2d32;
  color: #ffffff;
  border-color: #2a2d32;
  transition: background 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
}
.product .item > .right .shopify-payment-button__button--unbranded:hover {
  background: #131416;
}
.product .item > .right .shopify-payment-button__more-options {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  margin-top: 16px;
  margin-bottom: 28px;
}
.product .item > .right .shopify-payment-button__more-options.shopify-payment-button__button--hidden {
  display: none;
}
.product .item > .right .share {
  margin: 0;
  min-height: 0;
}
.product .item > .right .errors {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
  color: #a3be55;
  font-style: italic;
  text-transform: initial;
  margin: 10px 0 15px;
  text-align: center;
}
.product .close {
  display: none;
}
.product.preview {
  clear: left;
  min-height: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  padding: 30px;
  background: #ffffff;
}
.product.preview .item > .right a.title {
  display: block;
  margin-right: 80px;
  cursor: pointer;
}
.product.preview .item > .right h1.without-link {
  display: none;
}
.product.preview .close {
  display: block;
  position: absolute;
  right: -20px;
  top: -15px;
  color: #2a2d32;
}
.product.preview .close i.fc {
  font-size: 30px;
  border-radius: 50%;
  border: 2px solid;
  padding: 12px 10px 8px;
}
.show-background .product.preview {
  padding-left: 0;
  padding-right: 0;
}
.product.stack .item > .right {
  position: sticky;
  top: 10px;
}
.product .accordion {
  border-top: 1px solid #2a2d32;
  border-bottom: 1px solid #2a2d32;
}
.product .accordion summary {
  cursor: pointer;
  list-style: none;
  display: flex;
  position: relative;
  padding: 15px 5px;
}
.product .accordion .accordion-header {
  display: flex;
  flex: 1;
}
.product .accordion .accordion-header .icon-accordion {
  align-self: center;
  fill: #2a2d32;
  margin-right: 15px;
}
.product .accordion .accordion-header h3 {
  margin-right: 40px;
  display: inline-block;
}
.product .accordion .shopify-select-icon {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 18px;
  right: 5px;
  vertical-align: middle;
  fill: #2a2d32;
}
.product .accordion .rte {
  padding: 0 10px 15px;
  margin-top: -5px;
}
.product .accordion .rte img {
  max-width: 100%;
}
.product .accordion details[open] summary .shopify-select-icon {
  transform: rotate(180deg);
}
.product .type_collapsible_tab + .type_collapsible_tab {
  margin-top: -10px;
}
.product .type_collapsible_tab + .type_collapsible_tab .accordion {
  border-top: 0;
}
.product .rating-container .rating {
  display: inline-block;
  margin: 0;
}
.product .rating-container .rating-star {
  --color-base-text: 42, 45, 50;
  --color-icon: rgb(var(--color-base-text));
  --color-foreground: var(--color-base-text);
  --letter-spacing: 0.1;
  --font-size: 1.5;
  --percent: calc(
    (
        var(--rating) / var(--rating-max) + var(--rating-decimal) *
          var(--font-size) /
          (var(--rating-max) * (var(--letter-spacing) + var(--font-size)))
      ) * 100%
  );
  letter-spacing: calc(var(--letter-spacing) * 1rem);
  font-size: calc(var(--font-size) * 1rem);
  line-height: 1;
  display: inline-block;
  font-family: Times;
  margin: 0;
}
.product .rating-container .rating-star::before {
  content: '★★★★★';
  background: linear-gradient(90deg, var(--color-icon) var(--percent), rgba(var(--color-foreground), 0.15) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.product .rating-container .rating-text {
  display: none;
}
.product .rating-container .rating-count {
  display: inline-block;
  margin: 0;
  font-size: 18px;
  position: relative;
  top: -1px;
  margin-left: 5px;
}

@media (max-width: 960px) {
  .product .item > .left .product-media {
    margin-left: -10px;
    padding-left: 0;
  }
  .product .item > .left .product-media .frame {
    margin-left: 10px;
  }
  .product .item > .left .product-media .gallery .thumb {
    width: 25%;
    padding: 10px 0 0 10px;
  }
  .product .item > .left .product-media .gallery .thumb .box {
    height: 80px;
  }
  .product .item > .left .product-media.right .frame, .product .item > .left .product-media.left .frame {
    float: none;
    width: auto;
  }
  .product .item > .left .product-media.right .gallery, .product .item > .left .product-media.left .gallery {
    float: none;
    width: auto;
    padding: 0px;
    margin-left: -10px;
  }
  .product .item > .left .product-media.right .gallery .thumb, .product .item > .left .product-media.left .gallery .thumb {
    float: left;
    width: 25%;
    margin: 0;
    padding: 10px 0 0 10px;
  }
  .product.preview {
    margin-bottom: 10px;
    padding: 20px;
  }
}
@media (max-width: 960px) {
  .product .item > .right {
    padding-left: 20px;
  }
  .product.stack .item > .right {
    position: static;
  }
}
@media (max-width: 620px) {
  .product .item > .left, .product .item > .right {
    width: 100%;
    float: none;
  }
  .product .item > .left {
    margin: 0 0 15px;
  }
  .product .item > .left .product-media .gallery .thumb {
    width: 25%;
  }
  .product .item > .left .product-media .gallery .thumb .box {
    height: 80px;
  }
  .product .item > .left .product-media.stack .frame .table .cell .media {
    margin-bottom: 0;
    display: block;
  }
  .product .item > .left .product-media.stack .frame .table .cell .media.hide {
    display: none;
  }
  .product .item > .left .product-media.stack .gallery {
    display: block;
  }
  .product .item > .right {
    padding-left: 0;
  }
}
/* Gift Card */
body.template-gift_card .header .bar > .left, body.template-gift_card .header .bar > .right {
  display: none;
}
body.template-gift_card .side-cart, body.template-gift_card .side-nav, body.template-gift_card .search-overlay {
  display: none;
}
body.template-gift_card .gift-card {
  position: relative;
  margin: 30px 0;
  max-width: 600px;
}
body.template-gift_card .gift-card .card {
  width: 100%;
  border-radius: 30px;
}
body.template-gift_card .gift-card .amount {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 16px;
  text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 44px;
  color: #2a2d32;
  color: white;
  font-weight: 700;
}
body.template-gift_card .gift-card .code-outer {
  position: absolute;
  bottom: 16px;
  text-align: center;
  width: 100%;
}
body.template-gift_card .gift-card .code-inner {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  background-color: #fff;
  padding: .5em;
  border-radius: 4px;
  max-width: 450px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
body.template-gift_card .gift-card .code-inner strong {
  text-transform: uppercase;
  border-radius: 2px;
  border: 1px dashed #e5e5e5;
  padding: .4em .5em;
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 30px;
  color: #2a2d32;
  color: #2a2d32;
  line-height: 1;
}
body.template-gift_card .gift-card .tooltip-container {
  display: block;
  position: absolute;
  right: 50%;
  z-index: 3;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  top: -40%;
}
body.template-gift_card .gift-card .tooltip-label {
  display: block;
  position: relative;
  right: -50%;
  border: none;
  border-radius: 4px;
  background: #333;
  background: rgba(51, 51, 51, 0.9);
  min-height: 14px;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
  line-height: 16px;
  text-shadow: none;
  padding: .5em .75em;
  margin-left: .25em;
  font-size: .4em;
  color: white;
}
body.template-gift_card .gift-card .tooltip-label small {
  text-transform: uppercase;
  color: #b3b3b3;
  font-size: .875em;
}
body.template-gift_card .gift-card .tooltip-triangle {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 5px solid #333;
  border-top: 5px solid rgba(51, 51, 51, 0.9);
  position: absolute;
  left: 100%;
  bottom: 0;
  margin-left: -5px;
  margin-bottom: -5px;
}
body.template-gift_card .gift-card.expired .code-inner strong, body.template-gift_card .gift-card.disabled .code-inner strong {
  text-decoration: line-through;
}
body.template-gift_card .shape-wrapper {
  vertical-align: middle;
}
body.template-gift_card #QrCode {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
}
body.template-gift_card .apple-wallet {
  text-decoration: none;
}
body.template-gift_card .apple-wallet img {
  display: inline;
  margin-right: 10px;
  vertical-align: middle;
}
body.template-gift_card .shop-url {
  display: none;
}

@media (max-width: 960px) {
  body.template-gift_card .gift-card .amount {
    font-size: 30px;
    padding: 12px 8px;
  }
  body.template-gift_card .gift-card .tooltip-container {
    top: -30%;
  }
  body.template-gift_card .gift-card .code-outer {
    bottom: 10px;
  }
  body.template-gift_card .gift-card .code-inner strong {
    font-size: 18px;
  }
}
@media (max-width: 620px) {
  body.template-gift_card .main {
    background: #ffffff;
  }
  body.template-gift_card .main .boxes .box:first-child > .left {
    text-align: center;
  }
  body.template-gift_card .main .boxes .box > .right {
    text-align: center;
  }
  body.template-gift_card .main .boxes .box > .right .back {
    text-align: center;
  }
  body.template-gift_card .main .row {
    text-align: center;
  }
}
@media print {
  body.template-gift_card > iframe {
    display: none;
  }
  body.template-gift_card .boxes .box > .left, body.template-gift_card .boxes .box > .right {
    text-align: center;
  }
  body.template-gift_card .boxes .box > .right .row h3.shop-url {
    display: block;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    text-transform: none;
    font-size: 16px;
    color: #2a2d32;
  }
  body.template-gift_card .boxes .box > .left h2 {
    position: static;
  }
  body.template-gift_card .gift-card .amount {
    font-size: 50px;
    -webkit-print-color-adjust: exact;
  }
  body.template-gift_card .shop-button {
    display: none;
  }
  body.template-gift_card #QrCode {
    margin-top: 10px;
    margin-left: 0;
  }
}
/* Footer */
.footer {
  max-width: 1940px;
  margin: 30px auto 0;
}
.footer.collapsed {
  margin-top: 0;
}
.footer .top {
  background: #3e4450;
  padding: 30px;
}
.footer .top:after {
  display: table;
  content: '';
  clear: both;
}
.footer .top .col {
  float: left;
  width: 25%;
  padding-right: 20px;
}
.footer .top .col:after {
  display: table;
  content: '';
  clear: both;
}
.footer .top .col.right {
  text-align: right;
  padding-right: 0;
  padding-left: 20px;
}
.footer .top .col.center {
  text-align: center;
}
.footer .top .col h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 15px;
  color: #2a2d32;
  color: #a3be55;
  margin: 0 0 15px;
}
.footer .top .col .rte p, .footer .top .col p, .footer .top .col li {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 15px;
  line-height: 24px;
  color: #2a2d32;
  color: #ffffff;
  text-transform: none;
}
.footer .top .col .rte p a, .footer .top .col p a, .footer .top .col li a {
  color: #ffffff;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
}
.footer .top .col .rte p a:hover, .footer .top .col p a:hover, .footer .top .col li a:hover {
  text-decoration: underline;
  border-bottom: 0;
}
.footer .top .col .rte p.connect a, .footer .top .col p.connect a, .footer .top .col li.connect a {
  margin-left: 5px;
  color: #ffffff;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.footer .top .col .rte p.connect a i.fc, .footer .top .col p.connect a i.fc, .footer .top .col li.connect a i.fc {
  font-size: 34px;
}
.footer .top .col .rte p.connect a:hover, .footer .top .col p.connect a:hover, .footer .top .col li.connect a:hover {
  color: #ffffff;
}
.footer .top .col .rte p.success, .footer .top .col p.success, .footer .top .col li.success {
  color: #ffffff;
}
.footer .top .col .rte p {
  margin-bottom: 0;
}
.footer .top .col ul {
  list-style-type: none;
  float: left;
  width: 40%;
  max-width: 200px;
}
.footer .top .col ul li {
  margin: 0 0 4px;
}
.footer .top .col form .table {
  display: table;
  width: 100%;
  max-width: 360px;
  background: #3e4450;
}
.footer .top .col form .table input.text {
  padding: 6px 10px 5px;
  display: table-cell;
  width: 100%;
}
.footer .top .col form .table span.input-group-btn {
  display: table-cell;
  width: 1%;
  vertical-align: top;
}
.footer .top .col form .table span.input-group-btn .btn {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 17px;
  color: #2a2d32;
  color: #a3be55;
  font-weight: 700;
  background: transparent;
  border: 2px solid;
  padding: 3px 10px;
  margin-left: 5px;
  margin-right: 10px;
}
.footer .top.blocks-1 .col {
  width: 100%;
}
.footer .top.blocks-2 .col {
  width: 50%;
}
.footer .top.blocks-3 .col {
  width: 33.33%;
}
.footer .bottom {
  color: #ffffff;
  background: #2a2d32;
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
}
.footer .bottom:after {
  display: table;
  content: '';
  clear: both;
}
.footer .bottom .left, .footer .bottom .right {
  flex-grow: 1;
  align-self: center;
}
.footer .bottom .left {
  text-align: left;
  flex-basis: 65%;
}
.footer .bottom .right {
  text-align: right;
  flex-basis: 35%;
}
.footer .bottom .text {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  color: #ffffff;
  line-height: 18px;
}
.footer .bottom .text a {
  color: #ffffff;
  text-decoration: none;
}
.footer .bottom .text a:hover {
  color: #ffffff;
}
.footer .bottom .cards {
  margin: 0 0 10px;
  line-height: 0;
  display: flex;
  flex-wrap: wrap;
}
.footer .bottom .cards svg {
  width: 50px;
  height: 32px;
  display: block;
  margin: 1px;
  order: 99;
}
.footer .bottom .cards svg[aria-labelledby=pi-visa] {
  order: 1;
}
.footer .bottom .cards svg[aria-labelledby=pi-apple_pay] {
  order: 2;
}
.footer .bottom .cards svg[aria-labelledby=pi-google_pay] {
  order: 3;
}
.footer .bottom .cards svg[aria-labelledby=pi-shopify_pay] {
  order: 4;
}
.footer .bottom .cards svg[aria-labelledby=pi-paypal] {
  order: 5;
}
.footer .bottom .cards svg[aria-labelledby=pi-amex] {
  order: 6;
}
.footer .bottom .cards svg[aria-labelledby=pi-mastercard] {
  order: 7;
}
.footer .bottom .disclosures .disclosure {
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  color: #ffffff;
}
.footer .bottom .disclosures .disclosure:last-child {
  margin-left: 8px;
}
.footer .bottom .disclosures .disclosure .disclosure__toggle {
  color: #ffffff;
  border-color: #ffffff;
  background: transparent;
}
.footer .bottom .disclosures .disclosure .disclosure__toggle .shopify-select-icon {
  fill: #ffffff;
}
.footer .bottom .disclosures .disclosure .disclosure__toggle:hover, .footer .bottom .disclosures .disclosure .disclosure__toggle[aria-expanded="true"] {
  color: #ffffff;
  border-color: #ffffff;
}
.footer .bottom .disclosures .disclosure .disclosure__toggle:hover .shopify-select-icon, .footer .bottom .disclosures .disclosure .disclosure__toggle[aria-expanded="true"] .shopify-select-icon {
  fill: #ffffff;
}
.footer .bottom .disclosures .disclosure .disclosure__list {
  background-color: #2a2d32;
  border-color: #ffffff;
  right: 0;
}
.footer .bottom .disclosures .disclosure .disclosure__list li {
  text-align: right;
}
.footer .bottom .disclosures .disclosure .disclosure__list li a {
  color: #ffffff;
}
.footer .bottom .disclosures .disclosure .disclosure__list li a:hover {
  color: #ffffff;
  border-color: transparent;
}
.footer .bottom .disclosures .disclosure .disclosure__list li.disclosure-list__item--current a {
  border-color: #ffffff;
}

@media (max-width: 960px) {
  .footer {
    margin-top: 20px;
  }
  .footer .top {
    padding: 30px 30px 0;
  }
  .footer .top .col {
    width: 50%;
    margin-bottom: 30px;
  }
  .footer .top .col.right {
    text-align: left;
    padding-left: 0;
    padding-right: 20px;
  }
  .footer .top .col p.connect a {
    margin-left: 0;
    margin-right: 5px;
  }
  .footer .top.blocks-4 .col:nth-child(odd) {
    clear: left;
  }
  .footer .top.blocks-3 .col {
    width: 33.3333%;
  }
  .footer .bottom .left, .footer .bottom .right {
    flex-basis: 50%;
  }
}
@media (max-width: 620px) {
  .footer {
    margin-top: 15px;
  }
  .footer .top {
    padding: 60px 30px;
  }
  .footer .top .col {
    float: none;
    width: 100%;
    margin: 0 0 30px;
    padding-right: 0;
  }
  .footer .top .col:last-child {
    margin: 0;
  }
  .footer .top .col.right {
    float: none;
    text-align: left;
  }
  .footer .top .col h1 {
    font-size: 18px;
  }
  .footer .top .col p, .footer .top .col li {
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
  }
  .footer .top .col p.connect a, .footer .top .col li.connect a {
    color: #ffffff;
  }
  .footer .top .col ul {
    width: 50%;
  }
  .footer .top .col ul li {
    margin: 0 0 8px;
  }
  .footer .top .col form span.input-group-btn .btn {
    margin-right: 0px;
  }
  .footer .top.blocks-1 .col, .footer .top.blocks-2 .col, .footer .top.blocks-3 .col {
    width: 100%;
  }
  .footer .bottom {
    padding: 15px;
    flex-direction: column;
  }
  .footer .bottom .left, .footer .bottom .right {
    flex-basis: 100%;
    width: 100%;
    text-align: left;
  }
  .footer .bottom .right .text {
    margin-top: 4px;
  }
  .footer .bottom.with-selectors {
    padding: 15px 30px 30px;
  }
  .footer .bottom.with-selectors .left, .footer .bottom.with-selectors .right {
    text-align: center;
  }
  .footer .bottom.with-selectors .left {
    order: 2;
  }
  .footer .bottom.with-selectors .right {
    order: 1;
  }
  .footer .bottom .disclosures {
    margin: 20px 0;
  }
  .footer .bottom .disclosures .disclosure .disclosure__list {
    right: inherit;
  }
  .footer .bottom .disclosures .disclosure .disclosure__list li {
    text-align: left;
  }
  .footer .bottom .cards {
    width: 80%;
    justify-content: center;
    margin: 0 auto 10px;
  }
  .footer .bottom .cards svg {
    margin-bottom: 10px;
  }
}
/* Password */
.template-password .side-nav, .template-password .side-cart, .template-password .search-overlay {
  display: none;
}
.template-password .content {
  margin-bottom: 30px !important;
  padding: 0 !important;
}
.template-password .content .table {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}
.template-password .content .table .row {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 30px;
}
.template-password .content .table .row .login {
  display: none;
}
.template-password .content .table .row .login h4 {
  padding-top: 0;
}
.template-password .content .table .row a.logo {
  text-decoration: none;
}
.template-password .content .table .row a.logo h1 {
  margin-bottom: 30px;
}
.template-password .content .table .row a.logo h1 img {
  margin: 0 auto;
}
.template-password .content .table .row h2 {
  margin: 15px 0 20px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 65px;
  line-height: 66px;
  color: #2a2d32;
  font-weight: 700;
}
.template-password .content .table .row h3 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  font-style: italic;
  padding: 30px 0 25px;
}
.template-password .content .table .row h3.success {
  color: #a3be55;
}
.template-password .content .table .row h4 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  line-height: 26px;
  color: #2a2d32;
  font-style: italic;
  max-width: 600px;
  margin: 0 auto 20px;
}
.template-password .content .table .row p {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  color: #2a2d32;
  max-width: 400px;
  margin: -10px auto 20px;
}
.template-password .content .table .row p a {
  text-decoration: underline;
}
.template-password .content .table .row hr {
  margin: 0 auto;
}
.template-password .content .table .row hr.top {
  margin-top: 30px;
}
.template-password .content .table .row p.connect {
  font-size: 30px;
}
.template-password .content .table .row .banner {
  margin-top: 15px;
  font-size: 14px;
}
.template-password .content .table .row .banner a {
  text-decoration: none;
}
.template-password .content .table .row .banner a .shopify-svg {
  height: 30px;
  padding: 0 5px;
  position: relative;
  top: 6px;
}
.template-password .content .table .row .banner a span {
  display: none;
}
.template-password .content .table .row .form .inputs {
  max-width: 350px;
  margin: 0 auto;
  display: table;
  width: 100%;
}
.template-password .content .table .row .form .inputs input.text {
  padding: 16px 15px 15px;
  display: table-cell;
  width: 100%;
}
.template-password .content .table .row .form .inputs span.input-group-btn {
  display: table-cell;
  width: 1%;
  vertical-align: top;
}
.template-password .content .table .row .form .inputs span.input-group-btn .btn {
  padding: 14px 20px;
  margin-left: 5px;
  margin-right: 10px;
}
.template-password .content .table .row .form .link {
  margin-top: 20px;
}
.template-password .content .table a.btn[data-action] {
  margin: 30px auto;
  padding: 14px 20px;
}

@media (max-width: 960px) {
  .template-password .content {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}
@media (max-width: 620px) {
  .template-password .main .layout {
    padding: 15px 0;
  }
  .template-password .main .layout .content {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .template-password .main .layout .content .inner .table .row h1 {
    font-size: 30px;
    line-height: 30px;
  }
}
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 16px;
  color: #2a2d32;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #2a2d32;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #2a2d32;
}

.mfp-preloader a:hover {
  color: #595f69;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 28px;
  color: #2a2d32;
  color: #ffffff;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after, .mfp-arrow .mfp-a {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before, .mfp-arrow .mfp-b {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: zoom-out;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.mfp-ie7 .mfp-img {
  padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
  padding: 0;
}

.mfp-ie7 .mfp-content {
  padding-top: 44px;
}

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0;
}

.mfp-bg {
  background-color: #ffffff;
}
.mfp-bg.mfp-fade {
  -webkit-backface-visibility: hidden;
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s, 0.3s 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s, ease-out 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
.mfp-bg.mfp-fade.mfp-ready {
  opacity: 1;
  filter: alpha(opacity=100);
}
.mfp-bg.mfp-fade.mfp-removing {
  transition: all 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s, 0.3s 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s, ease-out 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
  opacity: 0;
  filter: alpha(opacity=0);
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s, 0.3s 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s, ease-out 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing {
  transition: all 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s, 0.3s 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s, ease-out 0.35s cubic-bezier(0.46, 0.01, 0.32, 1) 0s;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.mfp-fade.mfp-wrap.mfp-removing button {
  opacity: 0;
}

.mfp-counter {
  display: none;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: zoom-out;
}
.mfp-figure:after {
  box-shadow: none;
  background: none;
}

button.mfp-close {
  margin: 30px;
  font-size: em(40px);
  font-weight: 300px;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #2a2d32;
}

button.mfp-arrow {
  top: 0;
  height: 100%;
  width: 20%;
  margin: 0;
  opacity: 1;
  filter: alpha(opacity=100);
}
button.mfp-arrow:after, button.mfp-arrow .mfp-a {
  display: none;
}
button.mfp-arrow:before, button.mfp-arrow .mfp-b {
  display: none;
}
button.mfp-arrow:active {
  margin-top: 0;
}

.mfp-chevron {
  position: absolute;
  pointer-events: none;
}
.mfp-chevron:before {
  content: '';
  display: inline-block;
  position: relative;
  vertical-align: top;
  height: 25px;
  width: 25px;
  border-style: solid;
  border-width: 4px 4px 0 0;
  transform: rotate(-45deg);
}
.mfp-chevron.mfp-chevron-right {
  right: 55px;
}
.mfp-chevron.mfp-chevron-right:before {
  transform: rotate(45deg);
}
.mfp-chevron.mfp-chevron-left {
  left: 55px;
}
.mfp-chevron.mfp-chevron-left:before {
  transform: rotate(-135deg);
}

/*============================================================================
  Generate breakpoint-specific column widths and push classes
    - Default column widths: $grid-breakpoint-has-widths: ($small, $medium-up);
    - Default is no push classes
==============================================================================*/
/*============================================================================
  Flexbox prefix mixins from Bourbon
    https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_flex-box.scss
==============================================================================*/
/*============================================================================
  Grid Columns
    - Create width classes, prepended by the breakpoint name.
==============================================================================*/
/*================ Media Query Mixin ================*/
/*================ Responsive Text Alignment Helper ================*/
/* Whole */
.one-whole {
  width: 100%;
}

/* Halves */
.one-half {
  width: 50%;
}

/* Thirds */
.one-third {
  width: 33.3333333333%;
}

.two-thirds {
  width: 66.6666666667%;
}

/* Quarters */
.one-quarter {
  width: 25%;
}

.two-quarters {
  width: 50%;
}

.three-quarters {
  width: 75%;
}

/* Fifths */
.one-fifth {
  width: 20%;
}

.two-fifths {
  width: 40%;
}

.three-fifths {
  width: 60%;
}

.four-fifths {
  width: 80%;
}

/* Sixths */
.one-sixth {
  width: 16.6666666667%;
}

.two-sixths {
  width: 33.3333333333%;
}

.three-sixths {
  width: 50%;
}

.four-sixths {
  width: 66.6666666667%;
}

.five-sixths {
  width: 83.3333333333%;
}

/* Eighths */
.one-eighth {
  width: 12.5%;
}

.two-eighths {
  width: 25%;
}

.three-eighths {
  width: 37.5%;
}

.four-eighths {
  width: 50%;
}

.five-eighths {
  width: 62.5%;
}

.six-eighths {
  width: 75%;
}

.seven-eighths {
  width: 87.5%;
}

/* Tenths */
.one-tenth {
  width: 10%;
}

.two-tenths {
  width: 20%;
}

.three-tenths {
  width: 30%;
}

.four-tenths {
  width: 40%;
}

.five-tenths {
  width: 50%;
}

.six-tenths {
  width: 60%;
}

.seven-tenths {
  width: 70%;
}

.eight-tenths {
  width: 80%;
}

.nine-tenths {
  width: 90%;
}

/* Twelfths */
.one-twelfth {
  width: 8.3333333333%;
}

.two-twelfths {
  width: 16.6666666667%;
}

.three-twelfths {
  width: 25%;
}

.four-twelfths {
  width: 33.3333333333%;
}

.five-twelfths {
  width: 41.6666666667%;
}

.six-twelfths {
  width: 50%;
}

.seven-twelfths {
  width: 58.3333333333%;
}

.eight-twelfths {
  width: 66.6666666667%;
}

.nine-twelfths {
  width: 75%;
}

.ten-twelfths {
  width: 83.3333333333%;
}

.eleven-twelfths {
  width: 91.6666666667%;
}

/*================ Build Responsive Grid Classes ================*/
@media only screen and (max-width: 619px) {
  /* Whole */
  .small--one-whole {
    width: 100%;
  }

  /* Halves */
  .small--one-half {
    width: 50%;
  }

  /* Thirds */
  .small--one-third {
    width: 33.3333333333%;
  }

  .small--two-thirds {
    width: 66.6666666667%;
  }

  /* Quarters */
  .small--one-quarter {
    width: 25%;
  }

  .small--two-quarters {
    width: 50%;
  }

  .small--three-quarters {
    width: 75%;
  }

  /* Fifths */
  .small--one-fifth {
    width: 20%;
  }

  .small--two-fifths {
    width: 40%;
  }

  .small--three-fifths {
    width: 60%;
  }

  .small--four-fifths {
    width: 80%;
  }

  /* Sixths */
  .small--one-sixth {
    width: 16.6666666667%;
  }

  .small--two-sixths {
    width: 33.3333333333%;
  }

  .small--three-sixths {
    width: 50%;
  }

  .small--four-sixths {
    width: 66.6666666667%;
  }

  .small--five-sixths {
    width: 83.3333333333%;
  }

  /* Eighths */
  .small--one-eighth {
    width: 12.5%;
  }

  .small--two-eighths {
    width: 25%;
  }

  .small--three-eighths {
    width: 37.5%;
  }

  .small--four-eighths {
    width: 50%;
  }

  .small--five-eighths {
    width: 62.5%;
  }

  .small--six-eighths {
    width: 75%;
  }

  .small--seven-eighths {
    width: 87.5%;
  }

  /* Tenths */
  .small--one-tenth {
    width: 10%;
  }

  .small--two-tenths {
    width: 20%;
  }

  .small--three-tenths {
    width: 30%;
  }

  .small--four-tenths {
    width: 40%;
  }

  .small--five-tenths {
    width: 50%;
  }

  .small--six-tenths {
    width: 60%;
  }

  .small--seven-tenths {
    width: 70%;
  }

  .small--eight-tenths {
    width: 80%;
  }

  .small--nine-tenths {
    width: 90%;
  }

  /* Twelfths */
  .small--one-twelfth {
    width: 8.3333333333%;
  }

  .small--two-twelfths {
    width: 16.6666666667%;
  }

  .small--three-twelfths {
    width: 25%;
  }

  .small--four-twelfths {
    width: 33.3333333333%;
  }

  .small--five-twelfths {
    width: 41.6666666667%;
  }

  .small--six-twelfths {
    width: 50%;
  }

  .small--seven-twelfths {
    width: 58.3333333333%;
  }

  .small--eight-twelfths {
    width: 66.6666666667%;
  }

  .small--nine-twelfths {
    width: 75%;
  }

  .small--ten-twelfths {
    width: 83.3333333333%;
  }

  .small--eleven-twelfths {
    width: 91.6666666667%;
  }

  .small--text-left {
    text-align: left !important;
  }

  .small--text-right {
    text-align: right !important;
  }

  .small--text-center {
    text-align: center !important;
  }
}
@media only screen and (min-width: 620px) {
  /* Whole */
  .medium-up--one-whole {
    width: 100%;
  }

  /* Halves */
  .medium-up--one-half {
    width: 50%;
  }

  /* Thirds */
  .medium-up--one-third {
    width: 33.3333333333%;
  }

  .medium-up--two-thirds {
    width: 66.6666666667%;
  }

  /* Quarters */
  .medium-up--one-quarter {
    width: 25%;
  }

  .medium-up--two-quarters {
    width: 50%;
  }

  .medium-up--three-quarters {
    width: 75%;
  }

  /* Fifths */
  .medium-up--one-fifth {
    width: 20%;
  }

  .medium-up--two-fifths {
    width: 40%;
  }

  .medium-up--three-fifths {
    width: 60%;
  }

  .medium-up--four-fifths {
    width: 80%;
  }

  /* Sixths */
  .medium-up--one-sixth {
    width: 16.6666666667%;
  }

  .medium-up--two-sixths {
    width: 33.3333333333%;
  }

  .medium-up--three-sixths {
    width: 50%;
  }

  .medium-up--four-sixths {
    width: 66.6666666667%;
  }

  .medium-up--five-sixths {
    width: 83.3333333333%;
  }

  /* Eighths */
  .medium-up--one-eighth {
    width: 12.5%;
  }

  .medium-up--two-eighths {
    width: 25%;
  }

  .medium-up--three-eighths {
    width: 37.5%;
  }

  .medium-up--four-eighths {
    width: 50%;
  }

  .medium-up--five-eighths {
    width: 62.5%;
  }

  .medium-up--six-eighths {
    width: 75%;
  }

  .medium-up--seven-eighths {
    width: 87.5%;
  }

  /* Tenths */
  .medium-up--one-tenth {
    width: 10%;
  }

  .medium-up--two-tenths {
    width: 20%;
  }

  .medium-up--three-tenths {
    width: 30%;
  }

  .medium-up--four-tenths {
    width: 40%;
  }

  .medium-up--five-tenths {
    width: 50%;
  }

  .medium-up--six-tenths {
    width: 60%;
  }

  .medium-up--seven-tenths {
    width: 70%;
  }

  .medium-up--eight-tenths {
    width: 80%;
  }

  .medium-up--nine-tenths {
    width: 90%;
  }

  /* Twelfths */
  .medium-up--one-twelfth {
    width: 8.3333333333%;
  }

  .medium-up--two-twelfths {
    width: 16.6666666667%;
  }

  .medium-up--three-twelfths {
    width: 25%;
  }

  .medium-up--four-twelfths {
    width: 33.3333333333%;
  }

  .medium-up--five-twelfths {
    width: 41.6666666667%;
  }

  .medium-up--six-twelfths {
    width: 50%;
  }

  .medium-up--seven-twelfths {
    width: 58.3333333333%;
  }

  .medium-up--eight-twelfths {
    width: 66.6666666667%;
  }

  .medium-up--nine-twelfths {
    width: 75%;
  }

  .medium-up--ten-twelfths {
    width: 83.3333333333%;
  }

  .medium-up--eleven-twelfths {
    width: 91.6666666667%;
  }

  .medium-up--text-left {
    text-align: left !important;
  }

  .medium-up--text-right {
    text-align: right !important;
  }

  .medium-up--text-center {
    text-align: center !important;
  }
}
/*================ Flex item alignment ================*/
.align--top-middle {
  text-align: center;
}

.align--top-right {
  text-align: right;
}

.align--middle-left {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
}

.align--center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  text-align: center;
}

.align--middle-right {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  text-align: right;
}

.align--bottom-left {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
}

.align--bottom-middle {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  text-align: center;
}

.align--bottom-right {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  text-align: right;
}

/* Custom content */
.custom-content > .inner {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  -o-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: auto;
  margin-bottom: -30px;
  margin-left: -30px;
}
.custom-content .custom__item {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-bottom: 30px;
  padding-left: 30px;
  max-width: 100%;
  z-index: 2;
}
.custom-content .custom__item .collection-grid-item {
  margin-bottom: 0;
}
.custom-content .custom__item-inner {
  position: relative;
  display: block;
  text-align: left;
  max-width: 100%;
}
.custom-content .custom__item-inner--text h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 40px;
  color: #2a2d32;
  color: #2a2d32;
  font-weight: 700;
  word-wrap: break-word;
}
.custom-content .custom__item-inner--text .rte {
  margin-top: 20px;
}
.custom-content .custom__item-inner--text p {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  line-height: 25px;
  color: #2a2d32;
  text-transform: none;
  margin: 0px 0 20px 2px;
}
.custom-content .custom__item-inner--text p b, .custom-content .custom__item-inner--text p strong {
  font-weight: FontDrop;
}
.custom-content .custom__item-inner--text p a {
  text-decoration: underline;
}
.custom-content .custom__item-inner--text p:last-child {
  margin-bottom: 0;
}
.custom-content .custom__item-inner--video,
.custom-content .custom__item-inner--html {
  display: block;
}
.custom-content .custom__item-inner--image {
  position: relative;
  margin: 0 auto;
}
.custom-content .custom__image {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
}
.custom-content .placeholder-svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 350px;
}
.custom-content .video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  height: auto;
}
.custom-content .video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 620px) {
  .custom-content > .inner {
    margin-bottom: -15px;
    margin-left: -15px;
  }
  .custom-content > .inner.with-padding {
    padding: 15px;
  }
  .custom-content .custom__item {
    margin-bottom: 15px;
    padding-left: 15px;
  }
  .custom-content .background {
    left: 15px;
    bottom: 15px;
  }
}
/* Shopify Challenge (/challenge) */
.shopify-challenge__container {
  margin: 0 30px !important;
  padding: 40px 30px 60px !important;
  max-width: none !important;
  background: #ffffff;
}
.shopify-challenge__container .shopify-challenge__error {
  margin: 10px 0;
  font-size: 14px;
}
.shopify-challenge__container .btn.shopify-challenge__button {
  border: 3px solid;
}
.shopify-challenge__container .btn.shopify-challenge__button:hover {
  color: #595f69;
  border-color: #595f69;
}
.shopify-challenge__container .btn.shopify-challenge__button:hover:before, .shopify-challenge__container .btn.shopify-challenge__button:hover:after {
  border-color: #595f69;
}

@media (max-width: 960px) {
  .shopify-challenge__container {
    margin: 0 20px !important;
  }
  .shopify-challenge__container .btn.shopify-challenge__button {
    width: 304px;
  }
}
@media (max-width: 620px) {
  .shopify-challenge__container {
    margin: 0 15px !important;
  }
  .shopify-challenge__container .btn.shopify-challenge__button {
    width: 60%;
  }
}
/* Shopify Policy pages (/policies/privacy-policy) */
.shopify-policy__container {
  margin: 0 30px !important;
  padding: 0 0 30px !important;
  background: #ffffff;
  max-width: none !important;
  text-align: left;
}
.shopify-policy__container .shopify-policy__title,
.shopify-policy__container .shopify-policy__body {
  padding: 30px 30px 0;
  margin: 0 auto;
  max-width: 500px;
  text-align: inherit;
}
.shopify-policy__container .shopify-policy__title {
  width: 100%;
}
.shopify-policy__container .shopify-policy__title h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 29px;
  color: #2a2d32;
  color: #a3be55;
  font-weight: 700;
  text-decoration: none;
}
.shopify-policy__container .rte h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 34px;
  color: #2a2d32;
  font-weight: 700;
}

@media (max-width: 960px) {
  .shopify-policy__container {
    margin: 0 20px !important;
    padding: 0 0 20px !important;
  }
  .shopify-policy__container .shopify-policy__title,
  .shopify-policy__container .shopify-policy__body {
    padding: 20px 20px 0;
  }
}
@media (max-width: 620px) {
  .shopify-policy__container {
    margin: 0 15px !important;
    padding: 0 0 15px !important;
  }
  .shopify-policy__container .shopify-policy__title,
  .shopify-policy__container .shopify-policy__body {
    padding: 15px 15px 0;
  }
}
.header-expanded .shopify-challenge__container,
.header-expanded .shopify-policy__container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Email Marketing confirmation (?) */
.shopify-email-marketing-confirmation__container {
  margin: 0 30px !important;
  padding: 40px 0 60px !important;
  max-width: none !important;
  text-align: center;
  background: #ffffff;
}
.shopify-email-marketing-confirmation__container h1 {
  display: none;
}
.shopify-email-marketing-confirmation__container p {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  line-height: 27px;
  color: #2a2d32;
}
.shopify-email-marketing-confirmation__container a {
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
  color: #2a2d32;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  padding: 10px;
  background: transparent;
  border: 3px solid;
  margin: 20px auto 0;
  max-width: none;
}

@media (max-width: 960px) {
  .shopify-email-marketing-confirmation__container {
    margin: 0 20px !important;
  }
}
@media (max-width: 620px) {
  .shopify-email-marketing-confirmation__container {
    margin: 0 15px !important;
  }
}
  
  
.readmoretext {
    display: none;
}
  button.buttonreadmore {
    font-size: 15px;
    border: 1px solid;
    padding: 10px 23px;
    box-shadow: 1px 1px 6px #ddd;
    border-radius: 5px;
}
  h5.buttonreadmore span {
    font-size: 14px;
    border: 3px solid #000;
    padding: 10px 21px;
    margin-bottom: 4px;
    display: inline-block;
    border-radius: 2px;
    cursor: pointer;
}
  .readmoretext h4 {
    font-size: 18px;
    text-transform: capitalize;
}